import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Home } from './components/home'
import { Layout } from './components/layout'
import { Privacy } from './components/privacy'
import { Support } from './components/support'
import { About } from './components/about'
import { Product } from './components/product'
import { Corporate } from './components/corporate'
import { CookiePolicy } from './components/cookiepolicy'
import { MessagePermission } from './components/messagepermission'
import { ClarificationText } from './components/clarificationtext'
import { ApplicationForm } from './components/applicationform'
import { ExpressConsent } from './components/expressconsent'
import { LegalInformation } from './components/legalinformation'
import { UserAgreement } from './components/useragreemenet'
import { PageNotFound } from './components/pagenotfound'

import './css/mudi.css'
import './css/about.css'
import './css/legalinformation.css'
import '../public/robots.txt'
import '../public/sitemap.xml'
import '../public/basin-kiti.pdf'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="ozellikler" element={<Product />} />
          <Route path="hakkimizda" element={<About />} />
          <Route path="iletişim" element={<Contact />} />
          <Route path="destek" element={<Support />} />
          <Route path="kurumsal" element={<Corporate />} />
          <Route path="kvkk" element={<Privacy />} />
          <Route path="cookiepolicy" element={<CookiePolicy />} />
          <Route path="messagepermission" element={<MessagePermission />} />
          <Route path="clarificationtext" element={<ClarificationText />} />
          <Route path="applicationform" element={<ApplicationForm />} />
          <Route path="expressconsent" element={<ExpressConsent />} />
          <Route path="yasal-bilgiler" element={<LegalInformation />} />
          <Route path="useragreement" element={<UserAgreement />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
export default App

const Contact = () => {
  return <p>Contact</p>
}
