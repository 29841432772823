import React from 'react'

export const AboutFaq = () => {
  return (
    <div className="accordion mt-50 mb-60" id="aboutaccordionExample">
      <div className="faq-collapse-body">
        <span id="aboutheadingOne">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#aboutcollapseOne"
            aria-expanded="false"
            aria-controls="aboutcollapseOne"
          >
            <strong className="faq-message-title">
              {' '}
              Aracı kurum ne demek?{' '}
            </strong>
          </button>
        </span>
        <div
          id="aboutcollapseOne"
          className="collapse bg3"
          aria-labelledby="aboutheadingOne"
          data-bs-parent="#aboutaccordionExample"
        >
          <div className="faq-message-text">
            <p>
              Aracı kurum, sermaye piyasası faaliyetlerinde bulunmak üzere
              Sermaye Piyasası Kurulu (SPK) tarafından yetkilendirilmiş finansal
              şirketlerdir.
            </p>
            <p>
              Mundi’deki tüm yatırım hesapları ÜNLÜ Menkul Değerler A.Ş.
              bünyesinde açılmaktadır. ÜNLÜ Menkul Değerler A.Ş., Sermaye
              Piyasası Kurulu (SPK) ve Mali Suçları Araştırma Kurulu (MASAK)
              denetimine tabi bir aracı kurumdur.
            </p>
            <p>
              Yatırım hesabındaki sermaye piyasası yatırımların Yatırımcı Tazmin
              Merkezi (YTM) ile devlet güvencesi altındadır. Yatırımcı Tazmin
              Merkezi (YTM) güvencesiyle 2024 yılı için birikimin 1.143.379
              TL’ye kadar tazmin kapsamındadır. Bu tutar her yıl ilan edilen
              yeniden değerleme katsayısı oranında artırılır.
            </p>
          </div>
        </div>
      </div>
      <div className="faq-collapse-body">
        <span id="aboutheadingTwo">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#aboutcollapseTwo"
            aria-expanded="false"
            aria-controls="aboutcollapseTwo"
          >
            <strong className="faq-message-title">
              İş ortakları kimlerdir?
            </strong>
          </button>
        </span>
        <div
          id="aboutcollapseTwo"
          className="accordion-collapse collapse"
          aria-labelledby="aboutheadingTwo"
          data-bs-parent="#aboutaccordionExample"
        >
          <div className="faq-message-text">
            <p>
              Mundi’deki tüm yatırım hesapları ÜNLÜ Menkul Değerler A.Ş.
              bünyesinde açılmaktadır. ÜNLÜ Menkul Değerler A.Ş., Sermaye
              Piyasası Kurulu (SPK) ve Mali Suçları Araştırma Kurulu (MASAK)
              denetimine tabi bir aracı kurumdur.
            </p>
            <p>
              Mundi Kart altyapısı 6493 sayılı kanun ve ilgili mevzuata uygun
              şekilde Mundi’nin kart ve ödeme çözüm ortağı Sipay tarafından
              sağlanmaktadır. Sipay Elektronik Para ve Ödeme Hizmetleri A.Ş.,
              Türkiye Cumhuriyet Merkez Bankası (TCMB) lisanslı, 6493 sayılı
              kanun çerçevesinde hizmet veren bir elektronik para ve ödeme
              kuruluşudur.
            </p>
          </div>
        </div>
      </div>
      <div className="faq-collapse-body">
        <span id="aboutheadingFour">
          <button
            className="accordion-button collapsed bg1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#aboutcollapseFour"
            aria-expanded="false"
            aria-controls="aboutcollapseFour"
          >
            <strong className="faq-message-title">
              Mundi{"'"}ye nasıl ulaşabilirim?
            </strong>
          </button>
        </span>
        <div
          id="aboutcollapseFour"
          className="accordion-collapse collapse"
          aria-labelledby="aboutheadingFour"
          data-bs-parent="#aboutaccordionExample"
        >
          <div className="faq-message-text">
            <p>Mundi Destek Ekibi’ne ulaşabileceğin birden fazla kanal var.</p>
            <ul>
              <li>
                <a href="mailto:destek@getmundi.app">destek@getmundi.app</a>{' '}
                adresine e-posta gönderebilirsin.
              </li>
              <li>
                <a href="/destek">www.getmundi.app/destek</a> sayfamızdaki
                iletişim formunu doldurabilirsin.
              </li>
              <li>
                Uygulamada “Profil” ekranındaki “Yardım Merkezi” menüsünden
                destek talebini iletebilirsin.
              </li>
            </ul>
            <p>
              Her iş günü 09:00-18:00 saatleri arasında{' '}
              <a href="tel:0850 242 24 24">0850 242 24 24</a> numaralı telefonu
              arayabilirsin.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
