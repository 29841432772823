import React from 'react'
import { AboutFaq } from './aboutfaq'
import { Meta } from '../meta'
import { Investors } from './investors'
import { BussinessPartners } from './bussinesspartners'
import linkedin from '../img/LinkedInVektor.webp'
import teamCardImage1 from '../img/GokseninAkdeniz.webp'
import teamCardImage3 from '../img/OmerPaksoy.webp'
import teamCardImage4 from '../img/PelinAkpinar.webp'
import teamCardImage5 from '../img/OnurSecilmis.webp'
import teamCardImage6 from '../img/AliOsmanAlsoy.webp'
import teamCardImage7 from '../img/EmreYavuz.webp'
import teamCardImage8 from '../img/ArzuTiris.webp'
import teamCardImage9 from '../img/SinanBayar.webp'
import teamCardImage10 from '../img/DenizMelissaWilliams.webp'
import teamCardImage11 from '../img/SertacUzun.webp'
import teamCardImage12 from '../img/EserKaraismailoglu.webp'

export const About = () => {
  const metaTitle = 'Mundi - Vizyonumuz, İş Ortaklarımız ve Ekibimiz'
  const metaDescription =
    "Mundi'nin vizyonuyla birleşen lider iş ortaklarımız, yatırımcılarımız ve sana en iyi hizmeti sunan ekibimizle tanış. Daha fazlası için tıkla."
  Meta(metaTitle, metaDescription)

  return (
    <>
      <>
        <section>
          <div className="container">
            <div className="row mx-auto">
              <div className="col-sm-12">
                <div className="col-sm-8 offset-sm-2">
                  <h1 className="about-header-title mb-0">
                    Herkes için tutardan bağımsız{' '}
                    <br className="d-block d-sm-none" />
                    iyi getiriye inanıyoruz
                  </h1>
                </div>
                <div className="col-sm-6 offset-sm-3 about-header-body">
                  Bu vizyonla bir araya gelen alanında lider iş ortaklarımızı,
                  bizi destekleyen yatırımcılarımızı ve sana en iyi hizmeti
                  vermek için çalışan ekibimizi daha yakından tanıyabilirsin.
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="isortaklari">
          <BussinessPartners />
          <div className="container">
            <div className="row mb-3 mx-auto d-block d-sm-none">
              <div className="col">
                <div className="row mx-auto">
                  <div className="col-sm-12">
                    <button className="standart-button fullsize-button order-sm-1 order-md-3 w-100">
                      <a
                        className="reserve-button nounderline text-center d-sm-none"
                        href="https://2ykh.adj.st?adj_t=1dkrt8yx"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Uygulamayı İndir
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="Ekip" className="about-bg1">
          <div className="container">
            <div className="row">
              <div className="mb-120-collapse-16">
                <div className="row m-auto">
                  <div className="col m-auto">
                    <h2 className="about-team-title mb-0">Ekip</h2>
                    <div className="about-team-message">
                      Mundi’nin arkasındaki ekibi tanımak istiyorsan aşağıya göz
                      atabilirsin.
                    </div>
                  </div>
                </div>
                <div className="row mb-120 mt-30 m-auto">
                  <div className="row row-cols-1 row-cols-sm-2 g-4 mt-25 mb-120 m-auto">
                    <div className="col-sm-4">
                      <div className="card text-center about-card-body ">
                        <div className="text-center">
                          <img
                            className="card-img-top about-card-image text-center"
                            src={teamCardImage1}
                            alt="mundi"
                          ></img>{' '}
                        </div>
                        <div className="card-body about-text-frame">
                          <h5 className="card-title about-card-title">
                            Göksenin Akdeniz
                          </h5>
                          <p className="card-text about-card-text">
                            CO-FOUNDER & CEO
                          </p>
                          <p className="card-text about-card-text">
                            <a
                              className=""
                              target="_blank"
                              href="https://www.linkedin.com/in/goksenin/"
                              rel="noreferrer"
                            >
                              <img
                                className="about-linkedin-icon"
                                src={linkedin}
                                alt="linkedin"
                              ></img>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card text-center about-card-body ">
                        <div className="text-center">
                          <img
                            className="card-img-top about-card-image text-center"
                            src={teamCardImage3}
                            alt="mundi"
                          ></img>{' '}
                        </div>
                        <div className="card-body about-text-frame">
                          <h5 className="card-title about-card-title">
                            Ömer Paksoy
                          </h5>
                          <p className="card-text about-card-text">
                            CO-FOUNDER
                          </p>
                          <p className="card-text about-card-text">
                            <a
                              className=""
                              target="_blank"
                              href="https://www.linkedin.com/in/omer-paksoy/"
                              rel="noreferrer"
                            >
                              <img
                                className="about-linkedin-icon"
                                src={linkedin}
                                alt="linkedin"
                              ></img>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card text-center about-card-body ">
                        <div className="text-center">
                          <img
                            className="card-img-top about-card-image text-center"
                            src={teamCardImage12}
                            alt="mundi"
                          ></img>{' '}
                        </div>
                        <div className="card-body about-text-frame">
                          <h5 className="card-title about-card-title">
                            Eser Karaismailoğlu
                          </h5>
                          <p className="card-text about-card-text">
                            CHIEF SALES OFFICER
                          </p>
                          <p className="card-text about-card-text">
                            <a
                              className=""
                              target="_blank"
                              href="https://www.linkedin.com/in/eserkaraismailoglu/"
                              rel="noreferrer"
                            >
                              <img
                                className="about-linkedin-icon"
                                src={linkedin}
                                alt="linkedin"
                              ></img>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card text-center about-card-body ">
                        <div className="text-center">
                          <img
                            className="card-img-top about-card-image text-center"
                            src={teamCardImage4}
                            alt="mundi"
                          ></img>{' '}
                        </div>
                        <div className="card-body about-text-frame">
                          <h5 className="card-title about-card-title">
                            Pelin Akpınar
                          </h5>
                          <p className="card-text about-card-text">
                            HEAD OF CX & CONTENT
                          </p>
                          <p className="card-text about-card-text">
                            <a
                              className=""
                              target="_blank"
                              href="https://www.linkedin.com/in/akpinarpelin/"
                              rel="noreferrer"
                            >
                              <img
                                className="about-linkedin-icon"
                                src={linkedin}
                                alt="linkedin"
                              ></img>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card text-center about-card-body ">
                        <div className="text-center">
                          <img
                            className="card-img-top about-card-image text-center"
                            src={teamCardImage5}
                            alt="mundi"
                          ></img>{' '}
                        </div>
                        <div className="card-body about-text-frame">
                          <h5 className="card-title about-card-title">
                            Onur Seçilmiş
                          </h5>
                          <p className="card-text about-card-text">
                            HEAD OF SOFTWARE ENGINEERING
                          </p>
                          <p className="card-text about-card-text">
                            <a
                              className=""
                              target="_blank"
                              href="https://www.linkedin.com/in/onur-seçilmiş/"
                              rel="noreferrer"
                            >
                              <img
                                className="about-linkedin-icon"
                                src={linkedin}
                                alt="linkedin"
                              ></img>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card text-center about-card-body ">
                        <div className="text-center">
                          <img
                            className="card-img-top about-card-image text-center"
                            src={teamCardImage11}
                            alt="mundi"
                          ></img>{' '}
                        </div>
                        <div className="card-body about-text-frame">
                          <h5 className="card-title about-card-title">
                            Sertaç Uzun
                          </h5>
                          <p className="card-text about-card-text">
                            PRODUCT MANAGER
                          </p>
                          <p className="card-text about-card-text">
                            <a
                              className=""
                              target="_blank"
                              href="https://www.linkedin.com/in/suzun/"
                              rel="noreferrer"
                            >
                              <img
                                className="about-linkedin-icon"
                                src={linkedin}
                                alt="linkedin"
                              ></img>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card text-center about-card-body ">
                        <div className="text-center">
                          <img
                            className="card-img-top about-card-image text-center"
                            src={teamCardImage8}
                            alt="mundi"
                          ></img>{' '}
                        </div>
                        <div className="card-body about-text-frame">
                          <h5 className="card-title about-card-title">
                            Arzu Tırıs
                          </h5>
                          <p className="card-text about-card-text">
                            HEAD OF CUSTOMER SUPPORT
                          </p>
                          <p className="card-text about-card-text">
                            <a
                              className=""
                              target="_blank"
                              href="https://www.linkedin.com/in/arzu-t-9b925560/"
                              rel="noreferrer"
                            >
                              <img
                                className="about-linkedin-icon"
                                src={linkedin}
                                alt="linkedin"
                              ></img>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card text-center about-card-body ">
                        <div className="text-center">
                          <img
                            className="card-img-top about-card-image text-center"
                            src={teamCardImage6}
                            alt="mundi"
                          ></img>{' '}
                        </div>
                        <div className="card-body about-text-frame">
                          <h5 className="card-title about-card-title">
                            Ali Osman Alsoy
                          </h5>
                          <p className="card-text about-card-text">
                            SOFTWARE ENGINEER
                          </p>
                          <p className="card-text about-card-text">
                            <a
                              className=""
                              target="_blank"
                              href="https://www.linkedin.com/in/aliosmanalsoy/"
                              rel="noreferrer"
                            >
                              <img
                                className="about-linkedin-icon"
                                src={linkedin}
                                alt="linkedin"
                              ></img>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card text-center about-card-body ">
                        <div className="text-center">
                          <img
                            className="card-img-top about-card-image text-center"
                            src={teamCardImage7}
                            alt="mundi"
                          ></img>{' '}
                        </div>
                        <div className="card-body about-text-frame">
                          <h5 className="card-title about-card-title">
                            Emre Yavuz
                          </h5>
                          <p className="card-text about-card-text">
                            SOFTWARE ENGINEER
                          </p>
                          <p className="card-text about-card-text">
                            <a
                              className=""
                              target="_blank"
                              href="https://www.linkedin.com/in/emre-yavuz-99b304131"
                              rel="noreferrer"
                            >
                              <img
                                className="about-linkedin-icon"
                                src={linkedin}
                                alt="linkedin"
                              ></img>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card text-center about-card-body ">
                        <div className="text-center">
                          <img
                            className="card-img-top about-card-image text-center"
                            src={teamCardImage9}
                            alt="mundi"
                          ></img>{' '}
                        </div>
                        <div className="card-body about-text-frame">
                          <h5 className="card-title about-card-title">
                            Sinan Bayar
                          </h5>
                          <p className="card-text about-card-text">
                            SOFTWARE ENGINEER INTERN
                          </p>
                          <p className="card-text about-card-text">
                            <a
                              className=""
                              target="_blank"
                              href="https://www.linkedin.com/in/sinan-bayar-9a708b107"
                              rel="noreferrer"
                            >
                              <img
                                className="about-linkedin-icon"
                                src={linkedin}
                                alt="linkedin"
                              ></img>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card text-center about-card-body ">
                        <div className="text-center">
                          <img
                            className="card-img-top about-card-image text-center"
                            src={teamCardImage10}
                            alt="mundi"
                          ></img>{' '}
                        </div>
                        <div className="card-body about-text-frame">
                          <h5 className="card-title about-card-title">
                            Deniz Melissa Williams
                          </h5>
                          <p className="card-text about-card-text">
                            COMMUNICATION DESIGNER
                          </p>
                          <p className="card-text about-card-text">
                            <a
                              className=""
                              target="_blank"
                              href="https://www.linkedin.com/in/deniz-melissa-williams-60b883173/"
                              rel="noreferrer"
                            >
                              <img
                                className="about-linkedin-icon"
                                src={linkedin}
                                alt="linkedin"
                              ></img>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="Yatirimcilarimiz">
          <Investors />
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="faq-title mb-0">Sıkça Sorulan Sorular</h2>
                <AboutFaq />
                <div className="row">
                  <div className="text-center">
                    <p className="mt-55 mb-120">
                      <a
                        className="invitation-button-text nounderline"
                        href="/destek#Faq"
                      >
                        Daha Fazlası
                      </a>{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  )
}
