import React from 'react'
import { Meta } from '../meta'

export const CookiePolicy = () => {
  const metaTitle = 'Mundi - Çerezlere Yönelik Aydınlatma Metni'
  const metaDescription =
    'Web sitemiz ve mobil uygulamamızdaki çerez türleri, kullanım nedenleri ve ayarları hakkında bilgi edin.'
  Meta(metaTitle, metaDescription)

  return (
    <>
      <section className="legalinformation">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="mt-75 mb-120">
                <p className="legal-heading text-center ">
                  ÇEREZLERE YÖNELİK AYDINLATMA METNİ
                </p>
                <p className="legal-text text-center">
                  <i>
                    Bu dokümankişisel verilerinizin işlenmesi konusunda 6698
                    sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) m. 10
                    çerçevesinde aydınlatılmanızı sağlamak amacıyla
                    hazırlanmıştır.
                  </i>
                </p>
                <p className="legal-text align-justify">
                  Değerli Ziyaretçi,
                  <br />
                  <br />
                  Bu internet sitesi ve mobil uygulama,{' '}
                  <b>
                    <i>
                      Mudi Finansal Teknoloji Hizmetleri A.Ş. (“Şirket”),
                      Levazım Mah. Koru Sk. Zorlu Center Blok No:2 İç Kapı No:21
                      Beşiktaş/İstanbul,{' '}
                    </i>
                  </b>
                  <a href="/">
                    <i>www.getmundi.app</i>
                  </a>{' '}
                  tarafından işletilmektedir.
                  <br />
                  <br />
                  Sizleri internet sitemizde ve mobil uygulamamızda kullanılan
                  çerez türleri, çerezlerin kullanım amaçları ve bu çerezlerin
                  ayarları, yönetilmesi ve silinmesi hakkında bilgilendirmek
                  isteriz.
                  <br />
                  <br />
                  İnternet sitemizi ve mobil uygulamamızı şahsi herhangi bir
                  bilgi vermeden ziyaret edebilir, hizmetlerimiz hakkında bilgi
                  alabilirsiniz. Ziyaretlerinizde, site kullanımına ilişkin
                  bilgi toplamak için bazı çerezler kullanılmaktadır. Buradaki
                  amacımız; internet sitemizi ve mobil uygulamamızı ziyaret eden
                  kullanıcıya kolaylık sağlamak ve işleyişi daha iyiye
                  taşıyabilmektir.
                  <br />
                  <br />
                  İnternet sitemizde ve mobil uygulamamızda kullanılan,{' '}
                  <i>
                    Hedefleme ve Reklam Çerezleri ile Performans ve Analiz için
                    Gerekli Olan Çerezler ve İşlevsellik için Gerekli Olan
                    Çerezler
                  </i>
                  den birinin ve/veya her birinin kullanımına izin veriyorsanız,
                  internet sitemize ve/veya mobil uygulamamıza girdiğiniz anda
                  karşınıza çıkan pop-up üzerindeki “Kabul Et” seçeneğini
                  seçebilirsiniz. Bu çerezlerden hiçbirini almak istemiyorsanız,
                  bu pop-up üzerinden “Reddet” seçeneğini seçebilirsiniz. Bu
                  çerezler ile ilgili tercihlerinizi özelleştirmek veya önceden
                  kabul etmiş olduğunuz çerezlerin kullanımını durdurmak ve
                  tercihlerinizi değiştirmek istiyorsanız “Tercihler”
                  seçeneğinden Çerez Yönetimi Paneli’neulaşarak çerezlere
                  ilişkin tercihlerinizi değiştirebilirsiniz. Ziyaretçilerimiz,
                  İnternet Sitemizde ve mobil uygulamamızda yer alan{' '}
                  <i>Çerez Yönetim Paneli</i> üzerinden, kullanımda olan
                  çerezler ile ilgili bilgilere erişebilmekte ve{' '}
                  <i>Zorunlu çerezler</i> dışında kalan çerezler için
                  tercihlerini her zaman “açık” veya “kapalı” seçenekleri
                  üzerinden değiştirebilmektedir.
                </p>
                <div className="list-content">
                  <ol type="1" className="list-content">
                    <li className="legal-heading-2">Çerez Nedir?</li>
                    <p className="legal-text align-justify">
                      Çerez, bir siteyi ziyaret ettiğinizde tarayıcınız
                      aracılığıyla bilgisayarınıza veya mobil cihazınıza
                      kaydedilen küçük boyutlu bir metin dosyasıdır. Çerezler
                      bir sitenin daha verimli çalışmasının yanı sıra kişisel
                      ihtiyaçlarınıza daha uygun ve hızlı bir ziyaret deneyimi
                      yaşatmak için kişiselleştirilmiş sayfaların
                      sunulabilmesine olanak vermektedir. Çerezler sadece
                      internet ortamındaki ziyaret geçmişinize dair bilgiler
                      içermekte olup, bilgisayarınızda veya mobil cihazınızda
                      depolanmış dosyalara dair herhangi bir bilgi
                      toplamamaktadır.
                      <br />
                      <br />
                      Veriler tarayıcılara eriştiğiniz cihazlarınız aracılığıyla
                      toplanır. Toplanan bu bilgiler cihazlara özeldir.
                      İstenildiği zaman kullanıcı tarafından silinebilmekte ve
                      bilgilere erişim kapatılabilmektedir.
                    </p>
                    <li className="legal-heading-2">
                      İnternet Sitemizde ve Mobil Uygulamamızda Kullanılan Çerez
                      Türleri ve Kullanım Amaçları
                      <ol type="I">
                        <li className="legal-heading-2">
                          Geçerlilik Sürelerine Göre Çerezler
                          <p className="legal-text align-justify">
                            Geçerlilik sürelerine göre kalıcı çerez ve geçici
                            çerez olmak üzere iki çerez tipi bulunmaktadır.
                          </p>
                          <ol type="A">
                            <li className="legal-heading-2">
                              Geçici Çerezler (Oturum Çerezleri)
                            </li>
                            <p className="legal-text align-justify">
                              Geçici çerezler, diğer bir ifadeyle oturum
                              çerezleri, internet sitesini ziyaret ettiğiniz
                              esnada oluşarak oturumun sürekliliğinin sağlanması
                              amacıyla kullanılır ve sadece tarayıcınızı
                              kapatıncaya kadar geçerlidir.
                            </p>
                            <li className="legal-heading-2">Kalıcı Çerezler</li>
                            <p className="legal-text align-justify">
                              Kalıcı çerezler internet sitesini ziyaret
                              ettiğinizde oluşur ve siz silinceye veya süreleri
                              doluncaya kadar kalır, tarayıcınız kapatıldığı
                              zaman silinmez. Ayarlarınız ile uyumlu
                              kişiselleştirilmiş bir deneyim sunma gibi işlemler
                              için kalıcı çerezler kullanılmaktadır.
                            </p>
                          </ol>
                        </li>
                        <li className="legal-heading-2">
                          Kaynaklarına Göre Çerezler
                          <p className="legal-text align-justify">
                            Çerezlerin hangi kaynaktan/taraftan cihaza
                            yerleştiğine göre yapılan bir sınıflandırmadır.
                          </p>
                          <ol type="A">
                            <li className="legal-heading-2">
                              Birinci Taraf Çerezleri
                            </li>
                            <p className="legal-text align-justify">
                              Birinci taraf çerezleri, doğrudan ziyaret edilen
                              internet sitesi tarafından cihaza
                              yerleştirilmektedir.
                            </p>
                            <li className="legal-heading-2">
                              Üçüncü Taraf Çerezleri
                            </li>
                            <p className="legal-text align-justify">
                              Üçüncü taraf çerezleri, ziyaret edilen internet
                              sitesi tarafından değil, reklam veren veya
                              analitik sistem gibi üçüncü bir tarafça cihaza
                              yerleştirilen çerezlerdir.
                            </p>
                          </ol>
                        </li>
                        <li className="legal-heading-2">
                          İşlev ve Kullanım Amaçlarına Göre Çerezler
                          <p className="legal-text align-justify">
                            Çerezler, işlevleri ve kullanım amaçlarına göre şu
                            şekilde sınıflandırılabilmektedir:
                          </p>
                          <ol type="A">
                            <li className="legal-heading-2">
                              Zorunlu Çerezler
                            </li>
                            <p className="legal-text align-justify">
                              İnternet sitemizin ve mobil uygulamamızın doğru
                              biçimde çalışması ve güvenliği temin edebilmek
                              için zorunlu olan birinci taraf oturum
                              çerezleridir. Örneğin, kimlik doğrulama, gizlilik
                              tercihiniz, mevcut oturumunuz ile ilgili
                              bilgilerin kaybolmaması gibi amaçlarla zorunlu
                              çerezler kullanılmaktadır. Bu çerezler güvenlik ve
                              doğrulama gibi amaçlar için kullanılmakta olup,
                              herhangi bir pazarlama amacı doğrultusunda
                              kullanılmamaktadır.
                            </p>
                            <li className="legal-heading-2">
                              Performans ve Analiz için Gerekli Olan Çerezler
                            </li>
                            <p className="legal-text align-justify">
                              İnternet sitemizin ve mobil uygulamamızın
                              geliştirilmesine yardımcı olan birinci taraf
                              oturum çerezleridir. Bu tür çerezler,
                              ziyaretçilerin site kullanımları hakkında anonim
                              ve istatistiksel bilgiler toplar, sitenin
                              gerektiği gibi çalışıp çalışmadığının denetiminde
                              ve alınan hataların tespitinde kullanılır.
                              Örneğin; bir sayfada ne kadar süre harcadığınız,
                              hangi alt bağlantı adreslerine tıkladığınız,
                              internet sitesi ve mobil uygulamamızın
                              kullanımında herhangi bir hata mesajı alıp
                              almadığınız gibi bilgilere yönelik çerezlerdir.
                            </p>
                            <li className="legal-heading-2">
                              İşlevsellik için Gerekli Olan Çerezler
                            </li>
                            <p className="legal-text align-justify">
                              İnternet sitemizi ve mobil uygulamamızı ziyaret
                              eden kullanıcıların kişiselleştirmelerini ve
                              tercihlerini hatırlamasına olanak sağlayan
                              çerezlerdir. Örneğin, ziyaretçinin gizlilik
                              tercihi haricindeki, dil tercihi veya metin
                              fontboyu seçiminin hatırlanmasını sağlar. Bu tür
                              çerezlerin kullanımına izin verilmemesi, size özel
                              bir özelliğin kullanılmamasına neden olabilir ve
                              tercihlerinizi hatırlamasını engeller.
                            </p>
                            <li className="legal-heading-2">
                              Hedefleme ve Reklam Çerezleri
                            </li>
                            <p className="legal-text align-justify">
                              Bu çerezler internet sitelerinde veya site
                              haricindeki mecralarda hizmet tanıtımı yapmak, iş
                              birliği yapılan ortaklarla birlikte size ilgili ve
                              kişiselleştirilmiş reklamlar göstermek, reklam
                              kampanyalarının etkinliğini ölçmek için kullanılan
                              üçüncü taraf çerezledir.
                            </p>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      Kişisel Verilerin Hangi Amaçlarla İşleneceği ve Hukuki
                      Sebepler
                      <ol type="I">
                        <li className="legal-heading-2">Zorunlu Çerezler</li>
                        <p className="legal-text align-justify">
                          İnternet sitemizde ve mobil uygulamamızda kullanılan
                          zorunlu çerezler, internet sitemizi ve mobil
                          uygulamamızı ziyaret ederek talep etmiş olduğunuz
                          bilgi toplumu hizmetinin yerine getirilebilmesi
                          amacıyla kullanılmaktadır. Bu çerezler ile işlenen
                          kişisel verileriniz; Kanun’un md. 5/2.(c) hükmü
                          sözleşmenin ifası için gerekli olması veya Kanun’un
                          md. 5/2.(f) hükmü veri sorumlusunun meşru menfaatleri
                          kapsamında işlenmektedir.
                        </p>
                        <li className="legal-heading-2">
                          Performans ve Analiz için Gerekli Olan Çerezler
                        </li>
                        <p className="legal-text align-justify">
                          İnternet sitemizde ve mobil uygulamamızda kullanılan
                          performans ve analiz için gerekli olan çerezler,
                          internet sitemizin ve mobil uygulamamızın
                          geliştirilmesine yardımcı olmak amacıyla
                          kullanılmaktadır. Bu çerezler ile işlenen kişisel
                          verileriniz; Kanun’un md. 5/1 hükmü kapsamında, açık
                          rızanız alınarak işlenmektedir.
                        </p>
                        <li className="legal-heading-2">
                          İşlevsellik için Gerekli Olan Çerezler
                        </li>
                        <p className="legal-text align-justify">
                          İnternet sitemizde ve mobil uygulamamızda kullanılan
                          işlevsellik için gerekli olan çerezler, internet
                          sitemizin ve mobil uygulamamızın işlevselliğini
                          artırmak ve gizlilik tercihi dışındaki ayarlarınızın
                          kişiselleştirmesi amacıyla kullanılmaktadır. Bu
                          çerezler ile işlenen kişisel verileriniz; Kanun’un md.
                          5/1 hükmü kapsamında, açık rızanız alınarak
                          işlenmektedir.
                        </p>
                        <li className="legal-heading-2">
                          Hedefleme ve Reklam Çerezleri
                        </li>
                        <p className="legal-text align-justify">
                          İnternet sitemizde ve mobil uygulamamızda kullanılan
                          hedefleme ve reklam çerezleri, üçüncü taraf çerez
                          hizmet sağlayıcıları tarafından ziyaretçilerin ilgi
                          alanlarına göre profilinin çıkarılması ve
                          ziyaretçilere kendileri ile ilgili reklamlar
                          gösterilmesi amacıyla kullanılmaktadır. Bu çerezler
                          ile işlenen kişisel verileriniz; Kanun’un md. 5/1
                          hükmü kapsamında, açık rızanız alınarak işlenmektedir.
                        </p>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      İnternet Sitemizde Kullanılan Çerezler
                    </li>
                    <p className="legal-text align-justify">
                      İnternet sitemizin en altında ve mobil uygulamamızda yer
                      alan Çerez Yönetim Paneli linkine tıklayarak çerezler ile
                      ilgili tercihlerinizi her zaman değiştirebilirsiniz. Çerez
                      yönetim panelindeki “açık” veya “kapalı” tercihlerini
                      işaretleyerek ayarlarınızı kaydetmeniz gerekmektedir.
                      Ayarlarınızı kaydettikten sonra sayfayı yenilemeniz
                      üzerine yeni tercihleriniz kullanıma girecektir. Çerez
                      tercihleriniz hakkında daha fazla bilgi için lütfen
                      tarayıcınızın ‘yardım’ bölümünü gözden geçirin veya daha
                      detaylı bilgi için lütfen{' '}
                      <a
                        href="https://allaboutcookies.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://allaboutcookies.org/
                      </a>{' '}
                      adresini ziyaret edin.
                    </p>
                    <li className="legal-heading-2">Aktarım</li>
                    <p className="legal-text align-justify">
                      İnternet sitemizde ve mobil uygulamamızda kullandığımız
                      çerezler kapsamında elde edilen kişisel verilerinizi;
                      <ol type="1" className="list-text">
                        <li>
                          Bizim adımıza hizmet sunan (örneğin, bize mobil
                          aplikasyon destek hizmetleri sağlayan) servis
                          sağlayıcı şirketlerle ve kuruluşlarla paylaşabiliriz,
                        </li>
                        <li>
                          Kanunlara veya mahkeme kararına uymak için kişisel
                          verilerinizin paylaşılmasının gerekli olduğunu
                          düşündüğümüzde bilgilerinizi paylaşabiliriz. Ayrıca,
                          muhtemel bir suçun (örneğin, sahtecilik veya kimlik
                          hırsızlığı) önlenmesini ve soruşturulmasını sağlamak
                          için de paylaşabiliriz,
                        </li>
                        <li>
                          Yurt dışında yerleşik üçüncü taraf hizmet sağlayıcı
                          şirketler aracılığıyla kullandığımız çerezler ve
                          kullanılan bu çerezler kapsamında yurt dışına veri
                          aktarımının gerçekleştiği durumlarda, bu aktarımı
                          Kanun’un md. 9 hükmündeki şartlara uygun şekilde
                          gerçekleştirebiliriz.
                        </li>
                      </ol>
                    </p>
                    <li className="legal-heading-2">
                      Kişisel Verileriniz ile İlgili Haklarınız
                    </li>
                    <p className="legal-text align-justify">
                      Kanun’un 11. maddesi gereği kişisel verilerinizin;
                      <ol type="a" className="list-text">
                        <li>işlenip işlenmediğini öğrenme,</li>
                        <li>işlenmişse bilgi talep etme,</li>
                        <li>
                          işlenme amacını ve amacına uygun kullanılıp
                          kullanılmadığını öğrenme,
                        </li>
                        <li>
                          yurtiçinde/yurtdışında aktarıldığı 3. kişileri bilme,
                        </li>
                        <li>
                          eksik / yanlış işlenmişse düzeltilmesini isteme,
                        </li>
                        <li>
                          Kanun’un 7. maddesinde öngörülen şartlar çerçevesinde
                          silinmesini / yok edilmesini isteme,
                        </li>
                        <li>
                          aktarıldığı 3. kişilere yukarıda sayılan (d) ve (e)
                          bentleri uyarınca yapılan işlemlerin bildirilmesini
                          isteme,
                        </li>
                        <li>
                          münhasıran otomatik sistemler ile analiz edilmesi
                          nedeniyle aleyhinize bir sonucun ortaya çıkmasına
                          itiraz etme,
                        </li>
                        <li>
                          kanuna aykırı olarak işlenmesi sebebiyle zarara
                          uğramanız hâlinde zararın giderilmesini talep etme
                          hakkına sahipsiniz.
                        </li>
                      </ol>
                      Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi
                      tespit edici gerekli bilgiler ile Kanun’un 11. maddesinde
                      belirtilen haklardan kullanmayı talep ettiğiniz hakkınıza
                      yönelik açıklamalarınızı içeren talebinizi; Şirketimizin
                      internet sitesinde{' '}
                      <a href="/yasal-bilgiler">
                        www.getmundi.app/yasal-bilgiler
                      </a>{' '}
                      adresinde ve mobil uygulamamızda bulunan formu doldurmak
                      suretiyle, Şirketimizin “Levazım Mah. Koru Sk. Zorlu
                      Center Blok No:2 İç Kapı No:21 Beşiktaş/İstanbul” adresine
                      kimliğinizi tespit edici belgeler ile bizzat elden
                      iletebilir ya da noter kanalıyla gönderebilir veya
                      Şirketimizin{' '}
                      <a href="mailto:nemateknoloji@hs01.kep.tr">
                        nemateknoloji@hs01.kep.tr
                      </a>{' '}
                      KEP adresine kayıtlı eposta ile gönderebilirsiniz.
                      <br />
                      <br />
                      Çerezlere Yönelik Aydınlatma Metni ile ilgili soru ve
                      görüşlerinizi bize iletmek isterseniz, aşağıdaki iletişim
                      tercihlerinden birini kullanarak bizimle iletişime
                      geçebilirsiniz.
                      <br /> <br />
                      <div>
                        <div className="d-flex mb-1 position-relative">
                          <div className="legal-heading-2 pt-0 align-justify">
                            E-posta Adresi
                          </div>
                          <div className="legal-info-cookies legalinformation align-justify">
                            :{' '}
                            <a href="mailto:destek@getmundi.app">
                              destek@getmundi.app
                            </a>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex mb-1 position-relative">
                          <div className="legal-heading-2 pt-0 align-justify">
                            Posta Adresi
                          </div>
                          <div className="legal-info-cookies legalinformation align-justify">
                            : Levazım Mah. Koru Sk. Zorlu Center Blok No:2 İç
                            Kapı No:21 Beşiktaş/İstanbul
                          </div>
                        </div>
                      </div>
                    </p>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
