import React, { useState } from 'react'
import phone1 from '../img/phone1.webp'
import phone3 from '../img/phone3.webp'
import phone4 from '../img/phone4.webp'
import phone5 from '../img/phone5.webp'
import shortphone1 from '../img/shortphone1.webp'
import shortphone3 from '../img/shortphone3.webp'
import shortphone4 from '../img/shortphone4.webp'
import shortphone5 from '../img/shortphone5.webp'
import { RatesModal } from './ratesmodal'

export const Slider = () => {
  const [openModal, setOpenModal] = useState(true)

  function handleClick(event) {
    event.preventDefault()
    setOpenModal(true)
  }

  return (
    <div className="container px-0">
      <div className="row col-12 mx-auto">
        <div
          id="carouselExampleIndicators"
          className="carousel slide phone-slider px-0"
          data-bs-ride="carousel"
          data-bs-interval="36000000"
        >
          <div className="container">
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
            </div>
            <div className="carousel-inner justify-content-center mx-auto">
              <div className="carousel-item active">
                <div className="row row-cols-1 row-cols-sm-2 g-4 justify-content-center mx-auto">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-2 order-sm-1 mx-auto">
                    <div className="summary-title">
                      Mundi ile birikimlerini değerlendir
                    </div>
                    <div className="summary-message">
                      <ul>
                        <li>
                          {' '}
                          Yüksek getiri aramana gerek yok. Mundi’nin aracı kurum
                          partneri birikimlerin için sana uygun yatırım
                          imkanlarını ve bakiyen için avantajlı nema oranlarını
                          bulur.
                        </li>
                        <li className="mt-30">
                          Mundi’ye aktardığın birikimin boşta kalmaz, sermaye
                          piyasası yatırımlarına yönlendirilinceye kadar
                          otomatik olarak mevzuata uygun şekilde nemalanmaya
                          başlar.
                        </li>
                      </ul>
                      <div className="mt-55">
                        <button
                          className="summary-button"
                          onClick={handleClick}
                          data-bs-toggle="modal"
                          data-bs-target="#ratesModal"
                        >
                          Güncel Nema Oranları
                        </button>{' '}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 order-1 order-sm-2 mx-auto">
                    <div className="text-center">
                      <img
                        className="phone-slider-img-size mx-auto d-none d-sm-none d-md-block mt-75"
                        src={phone1}
                        alt="mundi-vector"
                      ></img>{' '}
                      <img
                        className="phone-slider-img-size d-md-none"
                        src={shortphone1}
                        alt="mundi-vector"
                      ></img>{' '}
                    </div>
                  </div>
                </div>
                <RatesModal openModal={openModal} />
                <div className="mb-120"></div>
              </div>
              <div className="carousel-item">
                <div className="row row-cols-1 row-cols-sm-2 g-4 justify-content-center mx-auto">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-2 order-sm-1 mx-auto">
                    <div className="summary-title">
                      Mundi Kart ile paranı dilediğinde harca
                    </div>
                    <div className="summary-message">
                      <ul>
                        <li>
                          Paranı dilediğinde vadesiz hesaplarına*
                          aktarabilirsin.
                        </li>
                        <li className="mt-30">
                          {' '}
                          Harcamalarını Mundi Kart’larından anında yapabilirsin.
                        </li>
                        <li className="mt-30">
                          İstersen sanal kartının yanında tamamen ücretsiz
                          fiziksel kartını da uygulama üzerinden talep
                          edebilirsin.
                        </li>
                      </ul>
                      <p className="information-text">
                        *Mundi Kart ve dijital cüzdan altyapısı 6493 sayılı
                        kanun ve ilgili mevzuata uygun şekilde Sipay Elektronik
                        Para ve Ödeme Hizmetleri A.Ş. tarafından sağlanmaktadır.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 order-1 order-sm-2 mx-auto">
                    <div className="text-center">
                      <img
                        className="phone-slider-img-size mx-auto d-none d-sm-none d-md-block mt-75"
                        src={phone3}
                        alt="mundi-vector"
                      ></img>{' '}
                      <img
                        className="phone-slider-img-size d-md-none"
                        src={shortphone3}
                        alt="mundi-vector"
                      ></img>{' '}
                    </div>
                  </div>
                </div>
                <div className="mb-120"></div>
              </div>
              <div className="carousel-item">
                <div className="row row-cols-1 row-cols-sm-2 g-4 justify-content-center mx-auto">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-2 order-sm-1 mx-auto">
                    <div className="summary-title">
                      İşlem maliyeti stresine girme
                    </div>
                    <div className="summary-message">
                      <ul>
                        <li>
                          Mundi’den banka hesaplarına yapacağın Türk Lirası ve
                          Amerikan Doları transferlerinde havale, EFT veya SWIFT
                          ücreti uygulanmaz.
                        </li>
                        <li className="mt-30">
                          {' '}
                          Mundi Kart ile yaptığın harcamalar da tamamen
                          masrafsızdır. Herhangi bir kart ücreti, aidat veya
                          komisyon ödemezsin.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 order-1 order-sm-2 mx-auto">
                    <div className="text-center">
                      <img
                        className="phone-slider-img-size mx-auto d-none d-sm-none d-md-block mt-75"
                        src={phone4}
                        alt="mundi-vector"
                      ></img>{' '}
                      <img
                        className="phone-slider-img-size d-md-none"
                        src={shortphone4}
                        alt="mundi-vector"
                      ></img>{' '}
                    </div>
                  </div>
                </div>
                <div className="mb-120"></div>
              </div>
              <div className="carousel-item">
                <div className="row row-cols-1 row-cols-sm-2 g-4 justify-content-center mx-auto">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-2 order-sm-1 mx-auto">
                    <div className="summary-title">7/24 para transferi yap</div>
                    <div className="summary-message">
                      <ul>
                        <li>
                          Vadesiz hesabındaki* parayı 7/24 banka hesabına
                          gönderebilir veya Mundi Kart ile harcayabilirsin.
                        </li>
                        <li className="mt-30">
                          {' '}
                          Yatırım hesabındaki parayı ise hafta içi her gün saat
                          09:00-15:00 arasında Mundi’deki vadesiz hesabına* veya
                          banka hesaplarına tamamen ücretsiz aktarabilirsin.
                        </li>
                        <li className="mt-30">
                          Sanal karta ek olarak ücretsiz fiziksel Mundi Kart’ı
                          da çok yakında uygulamadan talep edebileceksin.
                        </li>
                      </ul>
                      <p className="mt-30 information-text">
                        *Mundi Kart ve dijital cüzdan altyapısı 6493 sayılı
                        kanun ve ilgili mevzuata uygun şekilde Sipay Elektronik
                        Para ve Ödeme Hizmetleri A.Ş. tarafından sağlanmaktadır.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 order-1 order-sm-2 mx-auto">
                    <div className="text-center">
                      <img
                        className="phone-slider-img-size mx-auto d-none d-sm-none d-md-block mt-75"
                        src={phone5}
                        alt="mundi-vector"
                      ></img>{' '}
                      <img
                        className="phone-slider-img-size d-md-none"
                        src={shortphone5}
                        alt="mundi-vector"
                      ></img>{' '}
                    </div>
                  </div>
                </div>
                <div className="mb-120"></div>
              </div>
            </div>
            <button
              className="carousel-control-prev d-none d-md-block d-sm-none"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next d-none d-md-block d-sm-none"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
