import React from 'react'
import Logo1 from '../img/norma-logo.webp'
import Logo2 from '../img/mia-logo.webp'
import Logo3 from '../img/turkuaz-logo.webp'
import Logo4 from '../img/map-logo.webp'
import Logo5 from '../img/epias-logo.webp'
import Logo6 from '../img/microfon-logo.webp'
import Logo7 from '../img/studiosa-logo.webp'

const logos = [
  { src: Logo1, alt: 'norma' },
  { src: Logo2, alt: 'mia' },
  { src: Logo3, alt: 'turkuaz' },
  { src: Logo4, alt: 'map' },
  { src: Logo5, alt: 'epias' },
  { src: Logo6, alt: 'microfon' },
  { src: Logo7, alt: 'studiosa' },
]

export const CorporateSlider = () => {
  return (
    <>
      <div className="container">
        <div className="infinite-slider">
          <div className="infinite-slide-track">
            {logos.concat(logos).map((logo, index) => (
              <div className="corporate-slide" key={index}>
                <img
                  className={`${logo.alt}-logo`}
                  src={logo.src}
                  alt={logo.alt}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
