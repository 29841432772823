import React from 'react'

export const MessagePermission = () => {
  return (
    <>
      <section id="legalinformation">
        <div className="container">
          <div className="row mb-120">
            <div className="col-sm-12">
              <div className="mt-75">
                <p className="legal-heading text-center">
                  MUDİ FİNANSAL TEKNOLOJİ HİZMETLERİ ANONİM ŞİRKETİ
                  <br />
                  TİCARİ ELEKTRONİK İLETİ İZNİ
                </p>
                <p className="legal-text align-justify mt-32">
                  İşbu metin, 6563 sayılı Elektronik Ticaretin Düzenlenmesi
                  Hakkında Kanun kapsamında Nema Teknoloji Hizmetleri Anonim
                  Şirketi’nin (“Şirket”), tarafıma ilgili kanunlara uygun
                  olarak, standart bilgilendirmeler ve ürün ve hizmetler
                  hakkında duyurulara yönelik olarak ticari elektronik ileti ve
                  diğer iletiler göndermesi, aşağıda belirttiğim iletişim
                  bilgilerimin bu amaçlar ile alındığını ve tercih ettiğim
                  kanalla tarafıma iletiler gönderileceğini; iletişim
                  bilgilerimin gizliliklerinin korunması için gerekli
                  tedbirlerin alınarak işlenmesine dair kanuni ilkelere uygun
                  şekilde belirtilen amaçlarla üçüncü kişilerle
                  paylaşılabileceğine ve kanunlarda belirtilen diğer suretlerle
                  işlenebileceğine, ticari elektronik ileti içeriği ve diğer
                  kayıtların gerektiğinde T.C. Ticaret Bakanlığına sunulmak
                  üzere kayıt altına alınarak saklanabileceğine ve her zaman
                  iletişim tercihlerimi değiştirebileceğim veya hiçbir gerekçe
                  göstermeden verdiğim işbu iletişim iznini reddetme hakkımı
                  kullanarak iletişimi durdurabileceğime ve diğer tüm ilgili
                  hususlarda buradaki açıklamalar ve/veya sözleşmem ile
                  bilgilenmiş/bilgilendirilmiş olarak; Şirket tarafından sunulan
                  hizmetlere ilişkin olarak tarafıma bilgilendirme yapılması -
                  konusunda Şirket tarafından kanunlara uygun şekilde SMS/kısa
                  mesaj, telefonla arama, sosyal medya, e-posta/mail ve diğer
                  elektronik iletişim araçları-kanalları arasından aşağıda
                  tercih ettiğim iletişim kanallarıyla tarafıma ticari
                  elektronik iletiler ve diğer iletiler gönderilmesine ilişkin
                  onayımı içermektedir.
                </p>
                <p className="legal-text align-justify underline">
                  <i>
                    Tarafıma gönderilecek ticari elektronik iletilere ilişkin
                    işbu metni okuduğumu ve anladığımı kabul ve beyan ediyorum.
                    Bu kapsamda aşağıda tercih ettiğim kanallarla tarafıma ileti
                    gönderilmesine hür irademle onay veriyorum.
                  </i>
                </p>
                <p>
                  Telefon :
                  <br />
                  SMS :
                  <br />
                  E-posta :
                  <br />
                  <br />
                  <b>Ad Soyad :</b>
                  <br />
                  <b>Tarih :</b>
                  <br />
                  <b>İmza :</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
