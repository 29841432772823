import React from 'react'
import { ExpressConsent } from './expressconsent'
import { MessagePermission } from './messagepermission'
import { Privacy } from './privacy'
import { UserAgreement } from './useragreemenet'

export const PermissionModal = ({ openModal, permissionKey }) => {
  console.log(openModal)
  if (!openModal) {
    return null
  }

  return (
    <>
      <div
        className="modal fade"
        id="permissionModal"
        aria-labelledby="permissionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-12 mx-auto">
                  {(permissionKey == 1 && <Privacy />) ||
                    (permissionKey == 2 && <ExpressConsent />) ||
                    (permissionKey == 3 && <MessagePermission />) ||
                    (permissionKey == 4 && <UserAgreement />)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
