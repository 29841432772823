import { useEffect } from 'react'

export const Meta = (metatTitle, metaDescriptionText) => {
  useEffect(() => {
    document.title = metatTitle
    const metaDescription = document.querySelector('meta[name="description"]')

    if (metaDescription) {
      metaDescription.setAttribute('content', metaDescriptionText)
    } else {
      const newMetaDescription = document.createElement('meta')
      newMetaDescription.name = 'description'
      newMetaDescription.content = metaDescriptionText
      document.head.appendChild(newMetaDescription)
    }
  }, [])
}
