import React from 'react'

export const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <div className="page-not-found-text-large">404</div>
      <div className="page-not-found-text-small">
        Aradığın Sayfa Bulunamadı.
      </div>
      <div className="text-center mt-50">
        <a href="/" className="reserve-button text-center nounderline">
          Anasayfaya Dön
        </a>
      </div>
    </div>
  )
}
