import React from 'react'

export const UserAgreement = () => {
  return (
    <>
      <section id="useragreement">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="mt-75">
                <p className="legal-heading text-center">
                  KULLANICI SÖZLEŞMESİ
                </p>
                <div className="list-content">
                  <ol className="list-content">
                    <li className="legal-heading-2">
                      TARAFLAR
                      <p className="legal-text align-justify">
                        İşbu Kullanıcı Sözleşmesi <strong>(“Sözleşme”)</strong>,
                        İstanbul Ticaret Sicili Müdürlüğü nezdinde 351559-5
                        sicil numarasıyla tescilli ve Levazım Mah. Koru Sk.
                        Zorlu Center No:2 İç Kapı No:21 Beşiktaş / İstanbul
                        adresinde kayıtlı Mudi Finansal Teknoloji Hizmetleri
                        Anonim Şirketi <strong>(“Şirket”)</strong> ile Şirket
                        tarafından resmi ve yasal olarak kullanıma sunulan mobil
                        uygulamaya <strong>(“Mobil Uygulama”</strong> veya{' '}
                        <strong>“Mudi”) </strong>
                        erişim sağlayarak aşağıdaki şartları kabul eden ve üye
                        olan kullanıcılar <strong>(“Kullanıcı”)</strong>{' '}
                        arasında akdedilmiştir.
                        <br />
                        Şirket ve Kullanıcı, işbu Sözleşmede ayrı ayrı “Taraf”
                        ve birlikte “Taraflar” olarak anılacaklardır.
                      </p>
                    </li>
                    <li className="legal-heading-2">
                      TANIMLAR
                      <p className="legal-text align-justify mb-3">
                        <strong>
                          Elektronik Para Kuruluşu/Ödeme Kuruluşu:
                        </strong>{' '}
                        Şirket ile imzaladığı sözleşme ve 6493 Sayılı Kanun
                        hükümleri kapsamında, Kullanıcılara faaliyet iznine
                        sahip olduğu elektronik para ve/veya ödeme hizmetlerini
                        sunan şirketi,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Kullanıcı/Üye:</strong> İşbu Sözleşme’yi kabul
                        ederek Şirket tarafından sunulan hizmetlerden yararlanan
                        gerçek kişiyi,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Kullanıcı Hesabı:</strong> İşbu Kullanıcı
                        Sözleşmesi kapsamında Şirket tarafından Kullanıcıya
                        Mobil Uygulama üzerinde açılan hesabı,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>KVKK:</strong> 6698 Sayılı Kişisel Verileri
                        Koruma Kanunu’nu,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Mobil Uygulama:</strong> Şirket tarafından yasal
                        olarak kullanıma sunulmuş mobil uygulamayı,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Şifre:</strong> Mobil Uygulama’ya üyelik
                        sırasında Kullanıcı’nın kendisinin belirleyeceği ve
                        sadece kendisinin bileceği, kullanacağı ve bu aşamadan
                        itibaren Mobil 2 Uygulama’yı kullanmak için üye girişi
                        sırasında kullanılacak harf, numara ve karakterlerden
                        oluşan şifreyi,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Şirket:</strong> Mudi Finansal Teknoloji
                        Hizmetleri Anonim Şirketi’ni,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Üçüncü Taraf Hizmet Sağlayıcıları:</strong>{' '}
                        Şirket’in Mobil Uygulama üzerinde Kullanıcıların
                        yapabileceği işlemler ve/veya Kullanıcılara sunulan
                        hizmetler ile ilgili iş birliği yaptığı ve hizmet aldığı
                        aracı kuruluşlar, yatırım kuruluşları, elektronik para
                        kuruluşları ve ödeme kuruluşları gibi üçüncü taraf
                        hizmet sağlayıcıları şirketleri,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Yatırım Kuruluşu:</strong> Şirket ile imzaladığı
                        hizmet alım sözleşmesi ve yazılım lisans sözleşmesi
                        kapsamında, Mobil Uygulama üzerindeki bir bölümü
                        kiralayarak buradan kendisine ulaşan Kullanıcılara
                        faaliyet iznine sahip olduğu yatırım hizmetlerini sunan
                        şirketi,
                      </p>
                      <p className="legal-text align-justify">
                        <strong>Yatırım Hesabı:</strong> Yatırım Kuruluşu
                        tarafından sağlanan yatırım hizmetlerinden yararlanmak
                        için gerekli olan ve halihazırda varsa, Kullanıcı hesabı
                        ile eşleştirilen Yatırım Kuruluşu nezdindeki
                        hesaplarını; yoksa, Kullanıcıların gerekli yasal süreci
                        tamamladıktan sonra Yatırım Kuruluşu nezdinde
                        açabilecekleri hesabı,
                        <br />
                        ifade eder.
                      </p>
                    </li>
                    <li className="legal-heading-2">
                      SÖZLEŞMENİN KONUSU ve AMACI
                      <ol>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme, Kullanıcı’nın Şirket tarafından sunulan
                          hizmetleri kullanması dolayısıyla, Şirket ve Kullanıcı
                          arasında mevcut olan karşılıklı hak ve yükümlülükleri
                          düzenlemektedir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme, Kullanıcı’nın Mobil Uygulama’ya
                          elektronik ortamda üye olması ile kurulur. Kullanıcı,
                          Mobil Uygulama’ya Üye olurken işbu Sözleşme’nin tüm
                          hükümlerini ve her maddeyi ayrı ayrı okuduğunu, tüm
                          hükümleri onayladığını kabul, beyan ve taahhüt eder.
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      ŞİRKET TARAFINDAN VERİLEN HİZMETLER
                      <ol>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme çerçevesinde Şirket, Mobil Uygulaması
                          üzerinde Kullanıcılara, lisansa tabi olmayan hizmetler
                          sunacak ve Mobil Uygulama üzerinde zaman zaman gerekli
                          olan güncellemeleri yapacaktır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme çerçevesinde Şirket, alıcı-satıcı,
                          piyasa yapıcı, sermaye piyasası kurumu, yatırım
                          kuruluşu veya aracı kuruluş olmadığı gibi ve 6362
                          sayılı Sermaye Piyasası Kanununun 37. ve 38.
                          maddelerinde sayılan hizmet ve faaliyetleri
                          gerçekleştirmemektedir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Şirket tarafından Mudi’de piyasa verileri, finans
                          haberleri gibi herhangi bir lisansa tabi olmayan
                          hizmetler Kullanıcılara sunulmaktadır. Yanlış
                          anlaşılmaya mahal vermemek adına Şirket, işbu Sözleşme
                          kapsamında hizmet verirken, 6362 sayılı Sermaye
                          Piyasası Kanunu <strong>(“SPK”)</strong> ve ilgili
                          mevzuata aykırı iş ve işlemleri yapmayacak ve
                          özellikle aşağıdaki faaliyetlerde bulunmayacaktır:
                          <ul className="list-text list-style-disc list-text-2">
                            <li>
                              SPK ve ilgili mevzuatta mevzuatında tanımlanan
                              şekliyle emir iletimine aracılık faaliyetinde
                              bulunulması,
                            </li>
                            <li>
                              Yatırım hizmet ve faaliyetleriyle yan hizmetlerin
                              sunulması ve bunların pazarlanmasına yönelik
                              faaliyetlerin gerçekleştirilmesi,
                            </li>
                            <li>
                              Kullanıcı emirlerinin Yatırım Kuruluşuna
                              iletilmesi, emirlerin sonuçlarına ilişkin
                              bilgilendirme yapılması,
                            </li>
                            <li>
                              Halka arzda talep toplanması ve halka arz
                              hizmetleri verilmesi,
                            </li>
                            <li>
                              Sermaye piyasası araçlarının halka arzında
                              yüklenimde bulunularak veya bulunmaksızın satışa
                              aracılık edilmesi,
                            </li>
                            <li>
                              Yatırım Kuruluşunun sunabileceği yatırım
                              hizmetleri ve faaliyetlerinin yatırımcılara
                              tanıtılması, sözleşme akdedilmesine aracı
                              olunması,
                            </li>
                            <li>
                              Sözleşme yapmak isteyen tarafların komisyon
                              karşılığında bir araya getirilmesi faaliyetlerinin
                              yürütülmesi,
                            </li>
                            <li>
                              Sermaye piyasaları ile ilgili danışmanlık
                              hizmetleri sunulması,
                            </li>
                            <li>
                              Yatırım hizmetleri ve faaliyetleri ile sınırlı
                              olarak kredi ya da ödünç verilmesi ve döviz
                              hizmetleri sunulması,
                            </li>
                            <li>
                              Sermaye piyasası araçlarına ilişkin işlemlerle
                              ilgili yatırım araştırması ve finansal analiz
                              yapılması veya genel tavsiyede bulunulması,
                            </li>
                            <li>
                              Sermaye piyasası araçlarının müşteri namına
                              saklanması ve yönetimi ile portföy saklanması,
                            </li>
                            <li>
                              Sermaye piyasası araçlarının alınması ve
                              satılması,
                            </li>
                            <li>
                              Aracılık yükleniminin yürütülmesi ile ilgili
                              hizmetlerin sunulması,
                            </li>
                            <li>
                              Borçlanma veya başka yollardan finansman
                              sağlanmasında aracılık hizmeti sunulması,
                            </li>
                            <li>
                              Servet yönetimi ve finansal planlama yapılması.
                            </li>
                          </ul>
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Şirket tarafından sunulan hizmetlerin nasıl ve ne
                          şekilde sunulacağı, Mudi üzerinden yapılabilecek
                          işlemler, güncel şekilde Mobil Uygulama içerisinde
                          gösterilir. Mudi, yasal düzenlemeler, Şirket
                          politikaları ve sair sebeplerle sunduğu hizmetler ve
                          Mobil Uygulama üzerinde yapılabilecek işlemlerde
                          değişikliğe gidebilir. Bu halde Şirket, değişiklikleri
                          içeren haliyle hizmetlerin kapsamını ve ne şekilde
                          sunulacağına ilişkin değişiklikleri yine Mobil
                          Uygulama içerisinde duyurur.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Şirket tarafından verilen hizmetler hiçbir şekilde
                          yatırım tavsiyesi ve danışmanlık niteliğinde olmayıp
                          Şirket’in bu noktada hiçbir sorumluluğu
                          bulunmamaktadır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Şirket, Mobil Uygulama üzerinde Kullanıcıların
                          yapabileceği işlemler ve/veya Kullanıcılara sunulan
                          hizmetler ile ilgili Üçüncü Taraf Hizmet Sağlayıcıları
                          ile iş birliği yapması durumunda, Üçüncü Taraf Hizmet
                          Sağlayıcılarının sunduğu hizmetlerden yararlanmak
                          isteyen Kullanıcılar’ın yerine getirmesi gereken
                          işlemler ile tabi olacakları hüküm ve şartlar ilgili
                          Üçüncü Taraf Hizmet Sağlayıcıları tarafından
                          belirlenecektir. Şirket, Kullanıcılar ile Üçüncü Taraf
                          Hizmet Sağlayıcıları arasında kurulacak bu ve benzeri
                          hukuki ilişkilerin tarafı olmadığı gibi, kurulacak
                          hukuki ilişkilerden ötürü herhangi bir sorumluluğu da
                          bulunmamaktadır. Kullanıcılar, bu hususu bilerek Mobil
                          Uygulama üzerinde işlem yapmayı kabul, beyan ve
                          taahhüt ederler.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Şirket, yalnızca reşit (18 yaşından büyük) ve fiil
                          ehliyetine sahip Türk vatandaşları ile mavi kart
                          sahiplerine hizmet vermektedir. İşbu Sözleşme’de ifade
                          edilen hizmetlerden yararlanmak isteyen kişiler,
                          Sözleşme’nin kabulü ile Kullanıcı olarak Mobil
                          Uygulama üzerindeki hizmetlerden yararlanabilirler.
                          Ancak mevzuat ve Şirket politikaları gereği, Mobil
                          Uygulama’da işlemlerin gerçekleştirilmesi, ancak ve
                          ancak ilgili işlemin yapılmasına izin veren güvenlik
                          doğrulamasının yapılması sonrası mümkündür.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı, Mobil Uygulama’da Şirket tarafından sunulan
                          hizmetlerden işbu Sözleşme’ye Taraf olarak Üyeliğinin
                          devam ettiği süre boyunca yararlanabilecektir.
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      ÜÇÜNCÜ TARAF HİZMET SAĞLAYICILARININ SUNACAĞI HİZMETLER VE
                      SORUMLULUK
                      <ol>
                        <li className="legal-text align-justify nested-list">
                          Şirket, Mobil Uygulama üzerinde Kullanıcıların
                          yapabileceği işlemler ve/veya Kullanıcılara sunulan
                          hizmetler ile ilgili aracı kuruluşlar, yatırım
                          kuruluşları, elektronik para kuruluşları ve ödeme
                          kuruluşları gibi Üçüncü Taraf Hizmet Sağlayıcıları ile
                          iş birliği yapabilir, gerekli gördüğü her türlü
                          sözleşmeyi akdedebilir, iş birliği yaptığı Üçüncü
                          Taraf Hizmet Sağlayıcılarını değiştirebilir, iş
                          birliğini sonlandırabilir ve Üçüncü Taraf Hizmet
                          Sağlayıcıları ile akdettiği sözleşmeleri feshedebilir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Yatırım Kuruluşu, Şirket ile imzaladığı hizmet alım
                          sözleşmesi ve yazılım lisans sözleşmesi kapsamında;
                          Mudi üzerinden yatırım hizmetleri verebileceği bir
                          bölümü kiralayacak, buradan kendisine ulaşan
                          Kullanıcılara faaliyet iznine sahip olduğu yatırım
                          hizmetlerini aracı kurum olarak sunacaktır. Elektronik
                          Para ve Ödeme Kuruluşu da Şirket ile imzaladığı
                          sözleşme kapsamında, talep eden Kullanıcılara faaliyet
                          iznine sahip olduğu elektronik para ve/veya ödeme
                          hizmetlerini sunacaktır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Mudi’de Kullanıcı Hesabı açacak Kullanıcılar, Yatırım
                          Kuruluşu nezdinde Yatırım Hesabı açmaksızın Mudi
                          üzerinden sadece lisansa tabi olmayan hizmetlerden
                          yararlanabilecektir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Yatırım Kuruluşu tarafından sağlanacak yatırım
                          hizmetlerinden yararlanmak isteyen Kullanıcılar, Mobil
                          Uygulamanın ilgili bölümüne girip halihazırda Yatırım
                          Hesapları varsa doğrudan giriş yapacak, Yatırım
                          Hesapları yoksa gerekli yasal süreci tamamladıktan
                          sonra Yatırım Kuruluşu nezdinde Yatırım Hesabı
                          açabilecektir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Yatırım Kuruluşu tarafından sunulan hizmetlerden
                          yararlanmak isteyen Kullanıcılar ile Elektronik Para
                          ve Ödeme Kuruluşu tarafından sunulan hizmetlerden
                          yararlanmak isteyen Kullanıcılar’ın yükümlülükleri ile
                          tabi olacakları hüküm ve şartlar, ilgili Üçüncü Taraf
                          Hizmet Sağlayıcıları ile Kullanıcı arasında yapılacak
                          sözleşme kapsamında belirlenecektir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Mudi üzerinden Yatırım Kuruluşu nezdinde Yatırım
                          Hesabı açan Kullanıcılar veya halihazırda Yatırım
                          Kuruluşu nezdinde Yatırım Hesabı bulunan Kullanıcılar,
                          Mobil Uygulama üzerinden Yatırım Kuruluşu’nun
                          sağlayacağı yatırım hizmetlerinden
                          faydalanabilecektir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Yatırım Kuruluşu nezdinde Yatırım Hesabı açmak isteyen
                          Kullanıcılar ile Elektronik Para ve Ödeme
                          Kuruluşu’ndan hizmet almak isteyen Kullanıcılardan;
                          örnek olarak ancak bunlarla sınırlı olmamak kaydıyla
                          alınacak onaylar, müşterini tanı bilgileri ve
                          belgeleri, uygunluk ve yerindelik testleri bilgileri
                          ile imzalanacak yatırım sözleşmeleri, kullanıcı
                          sözleşmeleri ve ekleri Yatırım Kuruluşu ve Elektronik
                          Para ve Ödeme Kuruluşu tarafından sermaye piyasası
                          mevzuatı, ödeme hizmetleri ve elektronik para
                          kuruluşları mevzuatı, kişisel verilerin korunması
                          hakkındaki mevzuat ve diğer ilgili mevzuat hükümlerine
                          uygun olarak saklanacak ve korunacaktır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Yatırım Kuruluşu ve Elektronik Para ve Ödeme Kuruluşu,
                          SPK, TCMB, MASAK ve ilgili diğer tüm kurum/kuruluşlar
                          nezdinde münhasıran sorumlu olup, ilgili mevzuat
                          hükümleri kapsamında yükümlü olduğu tüm tedbirleri
                          alacaktır.
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ
                      <ol>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcıların, Mobil Uygulama’daki hizmetlerden
                          yararlanabilmesi için güvenlik doğrulaması aşamalarını
                          tamamlamaları gerekmektedir. Güvenlik doğrulaması
                          aşamalarını tamamlamayan kişiler ve yanlış bilgiler
                          veren kişiler hizmetlerden yararlanamayacakları gibi,
                          bu kişilerin hizmet alması Şirket tarafından askıya
                          alınabilir, durdurulabilir veya Mobil Uygulama’daki
                          Üyelikleri tamamen iptal edilebilir. Kullanıcının bu
                          yükümlülüğüne aykırı şekilde, gerçeği yansıtmayan
                          yahut eksik bilgiler vermesi dolayısıyla oluşan
                          herhangi bir zarar bakımından Şirket’in herhangi bir
                          sorumluluğu bulunmamaktadır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı, Mobil Uygulama’da yer alan hesaplarını
                          ancak kendisi kullanabilir, hesabı üzerinden bir
                          başkası adına veya hesabına hareket edemez.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcılar, işbu Sözleşmeyi ve Kullanıcı Hesabını
                          üçüncü kişilere devredemeyeceği gibi her ne ad altında
                          olursa olsun başka bir kişiye de kullandıramaz.
                          Kullanıcının hesabının başkası 7 tarafından
                          kullanılması sonucu meydana gelebilecek herhangi bir
                          sonuç bakımından sorumluluk Kullanıcı’ya aittir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme’nin icrası ve hizmetlerin
                          gerçekleştirilmesi kapsamında, Kullanıcı ile iletişime
                          geçilmesi gerekliliğinin doğması halinde; Şirket,
                          Kullanıcı tarafından verilen e-posta adresi ve telefon
                          numarası üzerinden Kullanıcı ile iletişime geçer.
                          Kullanıcı İletişim Bilgilerinde değişiklik olması
                          halinde sistem üzerinden İletişim Bilgilerini en kısa
                          sürede günceller. Kullanıcının bilgilerini
                          güncellememesi dolayısıyla oluşan zararlardan
                          Şirketsorumlu değildir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme kapsamında sunulan hizmetlerle ilgili
                          olarak gündeme gelebilecek sair mevzuat değişiklikleri
                          dolayısıyla Şirket, söz konusu değişiklik sonucu talep
                          etmesi gerekli olabilecek ek bilgi ve belgeleri
                          Kullanıcılardan talep edebilir, Kullanıcının bu bilgi
                          ve belgeleri talep edilen zaman içinde vermemesi yahut
                          gerçek dışı bilgiler vermesi halinde Şirket;
                          Kullanıcının hesabını askıya alabilir, hizmetlerden
                          yararlanmasını durdurabilir, Kullanıcının Üyeliğine
                          son verebilir. Kullanıcının işbu maddeye aykırı
                          davranışları dolayısıyla bir zararın doğması halinde
                          Şirket bu zarardan hiçbir şekilde sorumlu tutulamaz.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Her bir Kullanıcı, Mobil Uygulama üzerinde yalnızca
                          bir Kullanıcı Hesabına sahip olabilir. Bu maddeye
                          aykırılık halinde Şirket bu hususun düzeltilmesi için
                          gerekli olan tedbirleri alır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı, Mobil Uygulama üzerinde işlem yapabilmek
                          için; 18 yaşından büyük olduğunu ve fiil ehliyetine
                          sahip bulunduğunu, gerekli bilgi ve deneyime sahip
                          olduğunu kabul, beyan ve taahhüt eder.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Şirket, Mobil Uygulama üzerinde sunduğu hizmetleri
                          yedi gün yirmi dört saat olarak sunmayı planlamakla
                          birlikte, verdiği hizmetlerin kesintisiz olarak yedi
                          gün yirmi dört saat devam edeceğini garanti etmez.
                          Şirket, Mobil Uygulama üzerinde gerekli olan
                          geliştirme ve güncellemelerin yapılabilmesi adına
                          kesintiler gerçekleştirebilir. Üçüncü Taraf Hizmet
                          Sağlayıcıları tarafından sunulan hizmetlerden
                          yararlanma şartları ise ilgili Üçüncü Taraf Hizmet
                          Sağlayıcıları tarafından belirlenir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Mobil Uygulama üzerinde Üçüncü Taraf Hizmet
                          Sağlayıcıları tarafından sunulan hizmetlere ilişkin,
                          Şirket’in hiçbir yükümlülüğü olmadığı gibi, Şirket
                          buna ilişkin herhangi bir beyan ve taahhütte
                          bulunmamaktadır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Şirket, sunmuş olduğu hizmetlerin bir kısmında yahut
                          tamamında periyodik yahut periyodik olmayan bakım
                          çalışmaları yapabilir. Bakım çalışmaları gibi planlı
                          hizmet kesintileri mümkün mertebe Mobil Uygulama
                          ve/veya Kullanıcı tarafından tercih edilen iletişim
                          kanalları üzerinden duyurulur.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı, Mobil Uygulama’yı hukuka uygun bir biçimde
                          kullanır, Mobil Uygulama’nın hukuka veya işbu
                          Sözleşme’ye aykırı bir biçimde kullanılması halinde
                          tüm sorumluluk tamamen Kullanıcıya ait olup, Şirket’in
                          hukuka veya işbu Sözleşme’ye aykırı kullanımı tespit
                          etmesi halinde, Kullanıcı’nın üyeliğini askıya alma,
                          iptal etme, işlemlerini durdurma ve gerekli gördüğü
                          diğer tedbirleri alma hakkı vardır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Şirket, Mobil Uygulama’ya karşı gerçekleşebilecek
                          siber saldırılara yönelik gerekli önlemleri almakla
                          birlikte, alınan tüm önlemlere rağmen
                          gerçekleşebilecek siber saldırı, dolandırıcılık,
                          sahtekarlık olaylarından dolayı Şirket hiçbir şekilde
                          sorumlu tutulamaz.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı, Mobil Uygulama üzerinden yaptığı işlemler
                          dolayısıyla hukuken kendisine yüklenen
                          yükümlülüklerini yerine getirmek durumundadır.
                          Belirtilen yükümlülüklere aykırı davranılması halinde
                          meydana gelebilecek tüm sonuçlara ilişkin bütün
                          sorumluluk Kullanıcıya aittir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Şirket, Mobil Uygulama üzerinde sunulan hizmetleri
                          herhangi bir zamanda sonlandırmaya münhasıran
                          yetkilidir. Buna ilişkin bilgilendirme, Kullanıcılara
                          Mobil Uygulama üzerinden ve kullanıcıların Mobil
                          Uygulamada kayıtlı olan İletişim Bilgileri vasıtasıyla
                          bildirim yapılarak gerçekleştirilir.
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      KULLANICININ SORUMLULUĞU VE RİSK
                      <ol>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı’nın Mobil Uygulama üzerinde, Üçüncü Taraf
                          Hizmet Sağlayıcıları aracılığıyla gerçekleştirdiği
                          işlemler de dahil olmak üzere, yaptığı tüm işlemlerden
                          dolayı oluşan her tür sorumluluk tamamen kendisine
                          aittir. Şirket, Kullanıcı’nın Mobil Uygulama üzerinden
                          gerçekleştireceği işlemlere hiçbir şekilde müdahil
                          değildir. Kullanıcılar, Mobil Uygulama’ya girdiği
                          bilgilerin doğruluğundan emin olmalı ve yapacağı
                          işlemi, işlemin doğruluğunu teyit ettikten sonra
                          gerçekleştirmelidir. Kullanıcıların, Mobil Uygulama’yı
                          yanlış kullanmalarından doğabilecek her türlü risk
                          tamamen Kullanıcılara aittir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı, hesaplarına ilişkin olarak kullanıcı adı ve
                          Şifresi ile diğer bilgilerinin korunmasından, bu
                          bilgilerin işbu Sözleşme’de belirtilen istisnalar
                          hariç olmak üzere üçüncü kişilerle paylaşılmamasından
                          üçüncü kişilerin erişimine karşı muhafaza
                          edilmesinden, üçüncü kişilerin bu bilgilere erişmesini
                          önlemekten sorumludur. Kullanıcı, kendi kusuru
                          nedeniyle anılan bilgilerine bir başkasının erişmesi
                          dolayısıyla herhangi bir zararının oluşması halinde,
                          Şirket’in olası herhangi bir sonuç bakımından hiçbir
                          sorumluluğu bulunmadığını kabul etmektedir. Kullanıcı,
                          bilgilerine üçüncü kişi tarafından erişim sağlanması
                          halinde gerekli tedbirlerin alınabilmesi adına derhal
                          Şirket’i konuya ilişkin bilgilendirir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı, güçlü bir Şifre kullanması gerektiğini,
                          Şifresinin yalnızca Mudi Mobil Uygulamasına özgü
                          olması gerektiğini ve hesabının güvenliğinden
                          münhasıran kendisinin sorumlu olduğunu, söz konusu
                          sorumluluğuna uygun davranmaması nedeniyle doğabilecek
                          her türlü zarar, ziyan ve kayıptan sorumlu olduğunu ve
                          bu nedenle Şirket’e her ne nam altında olursa olsun
                          herhangi bir itirazda veya talepte bulunmayacağını
                          kabul, beyan ve taahhüt eder.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı; yazılım veya donanım yönünden ya da
                          internet bağlantılarından kaynaklanan sistemsel
                          hatalar ve bunlar dışında yaşanabilecek diğer tüm kötü
                          amaçlı yazılımların bulunma riskini ve bu nedenle 5.
                          madde kapsamında Üçüncü Taraf Hizmet Sağlayıcıları
                          tarafından sunulan hizmetlere ilişkin işlemlerinin
                          geçersiz sayılma ve/veya gecikme riskini kabul eder.
                          Kullanıcı ayrıca yaşanabilecek diğer siber
                          saldırıların, ağ hatalarının, bilgisayar virüslerinin,
                          iletişim hatalarının, sistemsel hataların, yetkisiz
                          erişimlerin, diğer siber saldırıların ve muhtemel
                          gecikmelerin yaşanabilme riskini de kabul eder.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Şirket, Mobil Uygulama’nın Kullanıcı’nın sahip olduğu
                          herhangi bir sistem, yazılım veya donanımla uyumlu
                          olduğuna/olacağına,sunulan hizmetlerin her an
                          ulaşılabilir olacağına ya da hiçbir kesinti olmadan
                          faaliyet göstereceğine ilişkin herhangi bir taahhüt
                          veya garanti vermemektedir. Kullanıcı, bu sorunların
                          yaşanma riskini kabul eder.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Şirket, üçüncü kişilerden kaynaklanabilecek olası
                          zararlardan sorumlu olmayıp, buna bağlı olarak
                          yaşanabilecek olası risklerden dolayı ortaya
                          çıkabilecek herhangi bir sonuçtan da sorumlu değildir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı, Mobil Uygulama’da yer alan analiz, görüş,
                          fiyat, haber, yorum, araştırma ve diğer piyasa
                          beklenti bilgilerinin yoruma dayalı olduğunu, hiçbir
                          şekilde yatırım tavsiyesi niteliği 10 taşımadığımı ve
                          Şirket’in herhangi bir öneri, tahmin ve yatırımdan
                          kaynaklanan olası kayıplardan sorumlu olmadığını kabul
                          eder.
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      KİŞİSEL VERİLERİN KORUNMASI VE GİZLİLİK
                      <p className="legal-text align-justify">
                        Şirket, Kullanıcılarına ait tüm kişisel verileri KVKK
                        kapsamında titizlikle korumaktadır. Kullanıcı tarafından
                        paylaşılan kişisel veriler, KVKK ve ilgili mevzuatlar
                        kapsamında gerektiği gibi, Şirket tarafından toplanmakta
                        ve işlenmektedir.
                      </p>
                    </li>
                    <li className="legal-heading-2">
                      HUKUKİ ve CEZAİ SORUMLULUK
                      <p className="legal-text align-justify">
                        Kullanıcı, Mobil Uygulama’yı Türkiye Cumhuriyeti
                        yasalarına uygun bir şekilde kullanacağını kabul, beyan
                        ve taahhüt eder.
                      </p>
                    </li>
                    <li className="legal-heading-2">
                      SÖZLEŞMEYE AYKIRILIK VE SÖZLEŞMENİN FESHİ
                      <ol>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme süresiz olup, Taraflar her zaman
                          Sözleşmeyi feshedebilir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı{"'"}nın işbu Sözleşme{"'"}ye yahut mevzuata
                          aykırı davranması halinde Şirket, Sözleşmeyi derhal ve
                          tek taraflı olarak feshedebilir. Kullanıcı, Sözleşme
                          {"'"}ye yahut mevzuata aykırı davranışından doğan
                          zararlardan dolayı münferiden kendisi sorumludur.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı’nın işbu Sözleşme’ye aykırı davranması,
                          Şirket tarafından verilen hizmetleri hukuka aykırı bir
                          biçimde kullanması, sunulan hizmetler bakımından
                          tehdit veya güvenlik riski oluşturması, Mobil
                          Uygulama’nın açıklarını bulmaya çalışarak bu şekilde
                          haksız kazanç sağlamaya çalışması gibi haklı bir
                          sebebin mevcut olması halinde de Şirket, Kullanıcının
                          hesaplarını dondurma, askıya alma, Üyeliğini sona
                          erdirme, gerekli diğer tedbirleri alma hakkına sahip
                          olduğu gibi 10.2 maddeye göre işbu Sözleşme’yi
                          feshedebilir.
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      FİKRİ MÜLKİYET HAKLARI
                      <ol>
                        <li className="legal-text align-justify nested-list">
                          Taraflar, Mobil Uygulama’ya ilişkin her türlü ürün ve
                          ürün bileşeninin mülkiyetiyle bunlar üzerindeki tüm
                          fikri ve sınai hakların ve bunlara ilişkin bütün mali
                          ve manevi hakların, sahibinin münferiden Şirket
                          olduğunu kabul, beyan ve taahhüt eder. Bu kapsamda,
                          burada yazılanlarla sınırlı olmamak kaydıyla, Şirket
                          tarafından sunulan hizmetlerin, bileşenlerinin,
                          yazılımlarının, ara yüzlerinin, görsellerin,
                          modellerin, logoların, algoritmaların, her türlü
                          tasarımın bütünü ve parçalarında ve fikri mülkiyete
                          konu üçüncü tarafça sağlanmayan her türlü fikri ve
                          sınai hakkın sahibi Şirket’tir. Şirket’in fikri
                          mülkiyetine tabi olan şeyler üzerinde her türlü işlem
                          Şirket’in yazılı iznine tabidir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı, Mudi üzerinde herhangi bir fikri mülkiyet
                          hakkına sahip değildir. Kullanıcı, Mobil Uygulama’da
                          izinsiz herhangi bir değişiklik yapmayacağını,
                          kopyalamayacağını, uyarlama yapmayacağını, kodlarına
                          ayırmayacağını, geri kodlamayacağını, ters mühendislik
                          uygulamayacağını, kaynak koda dönüştürmeyeceğini veya
                          uygulamadan başka bir kaynak kodu elde etmeye
                          çalışmayacağını kabul, beyan ve taahhüt eder.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme ile Taraflar arasında herhangi bir fikri
                          mülkiyet hakkının devrine yönelik bir anlaşma veya
                          lisans gibi kullandırma anlaşması kurulmadığı gibi bu
                          Sözleşme’nin de bu yönde yorumlanmayacaktır.
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      MÜCBİR SEBEP
                      <ol>
                        <p className="legal-text align-justify">
                          Sözleşme’nin imzalandığı tarihte var olmayan,
                          Taraflar’ın kontrolü haricinde meydana gelen ve
                          öngörülemeyen, Taraflar’ın veya bir Taraf’ın çalışma
                          imkânlarını kısmen veya tamamen durduracak ölçüdeki
                          salgın hastalık, pandemi, sel, çığ, deprem, heyelan
                          gibi doğa olayları, yangın, patlama, olağanüstü hava
                          koşulları, savaş, terör, işgal, ihtilal, darbe, hukuki
                          ve idari düzenlemeler, yasaklar, ambargolar, acil
                          ulusal ve bölgesel olaylar, grev, lokavt, teknik
                          aksaklıklar, olağandışı telekomünikasyon arızaları,
                          Üçüncü Taraf Hizmet Sağlayıcılarından kaynaklanan
                          sorunlar gibi haller mücbir sebep hali teşkil edecek
                          olup, Tarafların işbu Sözleşme kapsamında yer alan
                          yükümlülükleri bu mücbir sebebin etkili olduğu sürece
                          askıda olacaktır. Mücbir sebep hali ortadan kalkınca
                          Tarafların Sözleşme’den kaynaklanan yükümlülükleri
                          kaldığı yerden devam eder.
                        </p>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      DESTEK HİZMETLERİ
                      <p className="legal-text align-justify">
                        Kullanıcı, üyeliğinin sona erdirilmesi de dahil olmak
                        üzere hizmetlere ilişkin destek almak istemesi halinde{' '}
                        <a href="mailto:destek@getmundi.app">
                          destek@getmundi.app
                        </a>{' '}
                        e-posta adresi üzerinden Şirket ile iletişime geçebilir.
                      </p>
                    </li>
                    <li className="legal-heading-2">
                      DİĞER HÜKÜMLER
                      <ol>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşmede bulunan herhangi bir madde hiçbir
                          şekilde Kullanıcının yasal yükümlülüklerinin yerine
                          getirmesini, ilgili düzenlemelere uymasını ve
                          denetlenmesini, engelleyici şekilde yorumlanamaz.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Taraflardan herhangi birinin işbu Sözleşmeden doğan
                          herhangi bir hakkını, yetkisini veya imtiyazını
                          kullanmaması veya kullanmakta gecikmesi, söz konusu
                          haktan, yetkiden veya imtiyazdan feragat edildiği
                          anlamına gelmez. İşbu Sözleşme’de yer alan herhangi
                          bir hükümden, koşuldan veya şarttan feragat edilmesi,
                          söz konusu hükümden koşuldan veya şarttan sürekli
                          olarak feragat edildiği anlamına gelmez ve bu şekilde
                          feragat edildiği şeklinde yorumlanamaz.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı, doğrudan ya da dolaylı olarak tamamen veya
                          kısmen işbu Sözleşme’yi ve Sözleşme konusu hak ve
                          yükümlülüklerini başkalarına devir ve/veya temlik
                          edemez.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Şirket, her zaman için Sözleşme’den doğan hak,
                          yükümlülük, alacak ve sorumluluklarını üçüncü
                          taraflara devir ve temlik edebilir, üçüncü taraflarla
                          iş birliği yapabilir, sözleşme akdedebilir, alt
                          yüklenici veya temsilci kullanabilir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme’den doğan uyuşmazlıklara Türk Hukuku
                          uygulanacaktır. İşbu Sözleşmeden kaynaklanan
                          uyuşmazlıklarda İstanbul (Çağlayan) Mahkemeleri ve
                          İcra Daireleri yetkilidir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme’de yapılacak değişiklikler Mobil
                          Uygulama üzerinden Kullanıcı’nın elektronik onayına
                          sunulur ve onayı takiben hüküm ifade eder.
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      YÜRÜRLÜK
                      <ol>
                        <p className="legal-text align-justify">
                          İşbu Sözleşme 15 maddeden oluşmakta olup, Kullanıcı
                          tarafından elektronik ortamda kabul edildiği tarihte
                          yürürlüğe girer.
                        </p>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-120">
            <div className="col-sm-12">
              <div className="mt-75">
                <p className="legal-heading text-center">
                  ÖDEME VE ELEKTRONİK PARA HİZMETLERİ KULLANICI SÖZLEŞMESİ
                </p>
                <div className="list-content">
                  <ol className="list-content">
                    <li className="legal-heading-2">
                      TARAFLAR
                      <p className="legal-text align-justify">
                        İşbu Ödeme ve Elektronik Para Hizmetleri Çerçeve
                        Sözleşmesi <strong>(“Sözleşme”)</strong>, 6493 sayılı
                        Ödeme ve Menkul Kıymet Mutabakat Sistemleri, Ödeme
                        Hizmetleri ve Elektronik Para Kuruluşları Hakkında Kanun
                        uyarınca Altunizade Mh. Kuşbakışı Cd. No:17/2
                        Üsküdar/İSTANBUL adresinde mukim aşağıda detayları
                        verilen SiPay Elektronik Para ve Ödeme Hizmetleri A.Ş{' '}
                        <strong>(“SİPAY”)</strong> ile Ödeme Hizmetleri
                        kullanıcısı <strong>(“KULLANICI”)</strong> arasında
                        akdedilmiş bulunmaktadır.
                      </p>
                      <p className="align-justify">SİPAY Bilgileri:</p>
                      <div>
                        <div className="d-flex mb-1 position-relative">
                          <div className="legal-header2 align-justify">
                            Mersis No
                          </div>
                          <div className="legal-info-agreement legalinformation align-justify">
                            : 0771052810300001
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex mb-1 position-relative">
                          <div className="legal-header2 align-justify">
                            KEP adresi
                          </div>
                          <div className="legal-info-agreement legalinformation align-justify">
                            :{' '}
                            <a href="mailto:sipayelektronik@hs06.kep.tr">
                              sipayelektronik@hs06.kep.tr
                            </a>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex mb-1 position-relative">
                          <div className="legal-header2 align-justify">
                            E-posta adresi
                          </div>
                          <div className="legal-info-agreement legalinformation align-justify">
                            :{' '}
                            <a href="mailto:info@sipay.com.tr">
                              info@sipay.com.tr
                            </a>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex mb-1 position-relative">
                          <div className="legal-header2 align-justify">
                            Telefon numarası
                          </div>
                          <div className="legal-info-agreement legalinformation align-justify">
                            : <a href="tel:02127061112">0212 706 11 12</a>
                          </div>
                        </div>
                      </div>
                      <br />
                      <p className="legal-text align-justify">
                        İşbu Sözleşme kapsamında KULLANICI ve SİPAY müştereken
                        “Taraflar”, münferiden “Taraf” olarak anılacaktır.
                        <br />
                        <br />
                        İşbu Sözleşme, KULLANICI tarafından çevrimiçi veya
                        yazılı olarak kabulü veya imzalanmasıyla ilebirlikte
                        yürürlüğe girecek olup KULLANICI, SİPAY tarafından
                        sunulan ödeme ve elektronik para hizmetlerinin
                        kullanımının işbu Sözleşme ve varsa eklerinde yer alan
                        hüküm ve koşullara tabi olduğunu kabul eder.
                        <br />
                        <br />
                        İşbu Sözleşme kapsamında KULLANICI ve SİPAY müştereken
                        “Taraflar”, münferiden “Taraf” olarak anılacaktır.
                      </p>
                    </li>
                    <li className="legal-heading-2">
                      TANIMLAR
                      <p className="legal-text align-justify mb-3">
                        <strong>Alıcı:</strong> Ödeme ve/veya Elektronik Para
                        işlemine konu Fon’un ulaşması istenen gerçek veya tüzel
                        kişiyi,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Çağrı Merkezi:</strong> KULLANICI’nın 0212 706
                        11 12 nolu telefon hattı üzerinden şikayet, talep ve
                        önerilerini iletebileceği SİPAY Müşteri Hizmetlerini,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Elektronik Para:</strong> SİPAY tarafından kabul
                        edilen Fon karşılığı ihraç edilen, elektronik olarak
                        saklanan, Kanun’da tanımlanan Ödeme İşlemleri’ni
                        gerçekleştirmek için kullanılan ve SİPAY dışındaki
                        gerçek ve tüzel kişiler tarafından kabul edilen parasal
                        değeri,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Fon:</strong> Banknot, madeni para, kaydi para
                        veya elektronik parayı,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Gönderen:</strong> Kendi Ödeme Hesabı’ndan veya
                        Ödeme Hesabı bulunmaksızın Ödeme Emri veren gerçek veya
                        tüzel kişiyi,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Hassas Ödeme Verisi:</strong> Ödeme Emri’nin
                        verilmesinde veya kimlik doğrulamasında kullanılan, ele
                        geçirilmesi veya değiştirilmesi halinde dolandırıcılık
                        ya da sahte işlem yapılmasına imkân verebilecek şifre,
                        güvenlik sorusu, sertifika, şifreleme anahtarı ile PIN,
                        kart numarası, son kullanma tarihi, kart güvenlik kodu
                        gibi Ödeme Aracı’na ilişkin kişisel güvenlik
                        bilgilerini,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Hatalı veya Yetkisiz İşlem:</strong> Transfer
                        emrinin tümünün veya bir kısmının KULLANICI’nın iradesi
                        ve/veya bilgisi dışında gönderen veya transfer emri
                        verme konusunda yetkili olmayan üçüncü bir kişi
                        tarafından gönderilmesi sonucu gerçekleştirilen ve/veya
                        KULLANICI’nın ilgili mal veya hizmeti almadığı Ödeme
                        İşlemi,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Kart Saklama Hizmetleri:</strong> SİPAY
                        tarafından, KULLANICI’nın kredi kartı ve banka kartı
                        bilgilerini kaydetmesine ve SİPAY tarafından sunulan
                        hizmetler aracılığıyla yapacağı ödemelerde bu kayıtlı
                        kartlarını kullanmasına yönelik hizmetleri,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Kanun:</strong> 6493 sayılı Ödeme ve Menkul
                        Kıymet Mutabakat Sistemleri, Ödeme Hizmetleri ve
                        Elektronik Para Kuruluşları Hakkında Kanun’u,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Kurum:</strong> Türkiye Cumhuriyet Merkez
                        Bankası’nı,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Ödeme Aracı:</strong> KULLANICI’lar tarafından
                        Ödeme Emri’ni vermek için kullanılan kart, cep telefonu,
                        şifre, karekod ve benzeri ödeme araçlarını,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Ödeme Emri:</strong> Ödeme Hizmeti kullanıcısı
                        tarafından Ödeme İşlemi’nin gerçekleştirilmesi amacıyla
                        verilen talimatı,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Ödeme Hesabı:</strong> Ödeme Hizmeti kullanıcısı
                        tarafından Ödeme İşlemi’nin gerçekleştirilmesi amacıyla
                        verilen talimatı,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Ödeme Emri:</strong> Ödeme Hizmetleri
                        kullanıcısı adına açılan ve Ödeme İşlemleri’nin
                        yürütülmesinde kullanılan hesabı,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Ödeme Hizmetleri:</strong> Sözleşme’de
                        belirlenen hüküm ve koşullar çerçevesinde KULLANICI’ya
                        sunulacak olan Kanun’un 12. ve 18. maddelerinde sayılan
                        ödeme ve elektronik para hizmetlerinden SİPAY’nin
                        Kurum’dan faaliyet izni aldığı hizmetleri,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Ödeme İşlemi:</strong> KULLANICI veya
                        Gönderen’in talimatı üzerine gerçekleştirilen fon
                        yatırma, aktarma veya çekme faaliyetini,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Temsilci:</strong> Yönetmelik’in 13. maddesi
                        uyarınca SİPAY’nin Ödeme Hizmetlerini aracılığıyla
                        yürüttüğü kişileri,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Tüketici:</strong> Ticari veya mesleki olmayan
                        amaçlarla hareket eden gerçek veya tüzel kişiyi,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Uygulama:</strong> SİPAY tarafından sunulan
                        elektronik cüzdan uygulamasını,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Üye İşyeri:</strong> Elektronik ticaret
                        platformu ve/veya fiziksel ya da online işyeri üzerinden
                        sunduğu mal ve hizmetlerine dair ödemeleri, SİPAY
                        tarafından sağlanmakta olan ödeme hizmetleri aracılığı
                        ile alan gerçek veya tüzel kişiyi,
                      </p>
                      <p className="legal-text align-justify mb-3">
                        <strong>Web Sitesi:</strong>{' '}
                        <a href="https://www.sipay.com.tr/">
                          https://www.sipay.com.tr/
                        </a>{' '}
                        adresini,
                      </p>
                      <p className="legal-text align-justify">
                        <strong>Yönetmelik:</strong> Ödeme Hizmetleri ve
                        Elektronik Para İhracı ile Ödeme Kuruluşları ve
                        Elektronik Para Kuruluşları Hakkında Yönetmeliği ifade
                        eder.
                      </p>
                    </li>
                    <li className="legal-heading-2">
                      SÖZLEŞMENİN KONUSU
                      <p className="legal-text align-justify">
                        İşbu Sözleşme’nin konusu SİPAY tarafından KULLANICI’ya
                        sunulacak Ödeme Hizmetleri’nin kapsamının ve bu
                        hizmetlere ilişkin Taraflar’ın hak ve yükümlülüklerinin
                        belirlenmesidir. Bu kapsamda SİPAY, Sözleşme’nin
                        Yürürlük Tarihi itibariyle Ödeme Hizmetleri’ni bizzat
                        veya Temsilci aracılığı ile KULLANICI’ya sunacaktır.
                      </p>
                    </li>
                    <li className="legal-heading-2">
                      ÖDEME HİZMETLERİ’NE İLİŞKİN GENEL HÜKÜMLER
                      <ol>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme’ye konu Ödeme Hizmetleri Türk Lirası
                          cinsinden sunulabileceği gibi KULLANICI, Kurum
                          tarafından açıklanan tüm yabancı para birimleri ile
                          Ödeme Hizmetleri’nden faydalanabilir. SİPAY, referans
                          döviz kuru olarak Kurum’un belirlediği kurları
                          kullanmakta olup, döviz kurundaki değişiklikler derhal
                          geçerli olacaktır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          {' '}
                          KULLANICI, Ödeme Hizmetleri’ne ilişkin olarak işbu
                          Sözleşme’yi kabul etmeye ehil olduğunu, 5549 Sayılı
                          Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanun
                          ve ilgili yönetmelikler uyarınca; kendi adına ve
                          hesabına hareket ettiğini, başkası adına hareket
                          etmediğini, kendi adına ve fakat başkası hesabına
                          hareket etmesi ve/veya Ödeme Hesabı’nı üçüncü kişiye
                          devretmesi ile kimlik, iletişim ve diğer kimlik
                          tespitine konu bilgilerinin değişmesi halinde ise bu
                          durumları ve başkası hesabına hareket ediyorsa kimin
                          hesabına işlem yaptığını, yetki durumu ile hesabına
                          hareket edilen gerçek veya tüzel kişiliğin kimlik
                          bilgileri ve teyidine ilişkin belgelerini işlemlerin
                          yapılmasından önce 5549 sayılı Kanun dahil ilgili
                          mevzuata uygun olarak SİPAY’ye yazılı olarak
                          bildireceğini ve SİPAY’nin işbu bildirime istinaden,
                          kimlik tespit ve/veya bilgi temin talebinde
                          bulunabileceğini, işbu Sözleşme kapsamındaki
                          hizmetleri 5549 sayılı Kanun da dahil olmak üzere
                          mevzuatla belirlenen sınırlar dahilinde kullanacağını
                          kabul eder. Aksi halde SİPAY kendi ihmali veya
                          kusurundan kaynaklananlar hariç olmak üzere, SİPAY,
                          KULLANICI nezdinde gerçekleşen yetkisiz, hatalı
                          işlemler, üçüncü kişilerin haksız, hukuka aykırı
                          kullanımı, fraud vb. işlemler dahil hiçbir işlemden
                          sorumlu bulunmayacak ve sayılı hususlar dahil ancak
                          bunlarla sınırlı olmaksızın işbu Sözleşme ve özellikle
                          işbu maddenin ihlali halinde işbu Sözleşme’yi
                          tazminatsız tek taraflı olarak fesih etme, KULLANICI
                          hesabını, Ödeme Hesabını, Ödeme Hizmetleri’ni geçici
                          veya sürekli olarak askıya alma ve ilgili hesaplardaki
                          tutarlara bloke koyma hakkına sahip olacaktır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          KULLANICI, SİPAY tarafından Ödeme Hizmetleri’nin
                          gerçekleştirilebilmesi için, ilgili Ödeme İşlemi’nin
                          mahiyetine göre kart numarası, iletişim bilgileri,
                          Alıcı’nın ad-soyad, unvanı, adresi, banka, hesap ve
                          IBAN bilgileri gibi işlemin yapılabilmesi için gerekli
                          olan talep edilecek tüm bilgileri vermekle yükümlü
                          olduğunu kabul, beyan ve taahhüt eder. KULLANICI,
                          Ödeme Hizmetleri kapsamında SİPAY tarafından
                          kullanılabilecek sair sistemler doğrultusunda
                          kendisinden ek/farklı bilgiler talep edilebileceğini
                          bildiğini kabul eder.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          KULLANICI, verdiği bilgilerin yanlışlığından veya
                          açık, anlaşılır olmaması, belirli bir zamanda
                          yapılması talep edilen işlemlerde işlemin yapılması
                          gereken süreyi bildirmemesi gibi Ödeme İşlemi
                          kapsamındaki sorumluluklarını gereği gibi yerine
                          getirmemesinden kaynaklanan her türlü zararın ve
                          sorumluluğunun münhasıran kendisine ait olacağını
                          kabul ve beyan eder.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          KULLANICI, Ödeme Hizmetleri’nden yararlanılabilmek
                          için SİPAY nezdinde bir hesap açması gerektiğini kabul
                          eder. KULLANICI, doğrudan SİPAY’nin hesap oluşturma
                          arayüzlerini kullanmaksızın, Üye İşyeri’nde
                          oluşturulmuş hesaplar vasıtasıyla veya herhangi başka
                          bir kimlik eşleştirme mekanizması üzerinden hesap
                          oluşturması veya herhangi başka bir hesabı ile Ödeme
                          Hesabı’nı eşleştirmesi halinde söz konusu entegrasyon
                          kapsamında ilgili üçüncü taraflara hesabına erişim
                          için gerekli izin ve yetkileri vereceğini bu kapsamda
                          SİPAY ile ilgili taraf arasında KULLANICI’ya ait
                          bilgilerin paylaşılabileceğini kabul eder. SİPAY
                          tarafından belirlenen belirli işlem tutarları altında
                          kalan Ödeme İşlemleri için kimlik doğrulaması veya
                          eşleştirmesi yapılmaksızın sunulan hizmet ve servisler
                          bu hükme aykırılık teşkil etmez.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          KULLANICI, Sözleşme kapsamında SİPAY tarafından
                          belirlenen işlem limiti üzerinde işlem
                          gerçekleştiremeyeceğini kabul eder. İşlem limitleri
                          SİPAY tarafından belirlenecek ve Web Sitesi’nde
                          yayınlanacaktır. İşlem limitinin yükseltilmesinin
                          KULLANICI tarafından talep edilmesi ve bu talebin
                          SİPAY tarafından uygun bulunması halinde, SİPAY işlem
                          limitini yükseltmek için Web Sitesi’nde belirtilecek
                          bir ücret talep edebilecek ve Suç Gelirlerinin
                          Aklanmasının ve Terörün Finansmanının Önlenmesine Dair
                          Tedbirler Hakkında Yönetmelik kapsamında gerekli ve
                          SİPAY tarafından getirilen ek kimlik doğrulama
                          süreçlerinden geçmeyi zorunlu tutabilecektir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          KULLANICI, Ödeme İşlemi’ne ilişkin Ödeme Emri’ni ve
                          buna ilişkin onayını Uygulama üzerinden, gerekli
                          doğrulamalar yapılmış olan Üye İşyeri nezdinde
                          öngörülen süreç ile, Ödeme Aracı ile veya SİPAY’nin
                          kabul etmesi halinde sair kalıcı veri saklayıcıları
                          (kısa mesaj, elektronik posta ve benzeri her türlü
                          araç veya ortam) aracılığıyla da verebilir. Söz konusu
                          Ödeme Emri ve Ödeme Emri’ne ilişkin onay, Ödeme
                          İşlemi’nin gerçekleşmesinden önce veya sonra
                          verilebilir. KULLANICI’nın Ödeme Emri’ne ilişkin
                          onayı, açıkça (sarahaten) verilecek olup örtülü
                          (susma, itiraz etmeme, icazet ve sair şekilde zımnen)
                          kabuller Ödeme İşlemi onayı olarak kabul
                          edilmeyecektir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Ödeme Emri’nin SİPAY’e iletildiği an, Ödeme Emri’nin
                          alındığı an olarak kabul edilecek olup, Ödeme
                          İşlemi’nin belirli bir günde gerçekleştirilmesine
                          karar verilmesi halinde ise, Ödeme İşlemi için
                          kararlaştırılan gün, talimatın alınma tarihi kabul
                          edilir. KULLANICI, söz konusu Ödeme Emri’nin tatil
                          günleri hariç saat 24:00 (00:00)’e kadar
                          iletilebileceğini, daha geç vakitte gelen Ödeme
                          Emri’nin SİPAY tarafından ertesi iş günü alınmış
                          sayılacağını kabul ve beyan eder. KULLANICI, Ödeme
                          İşlemi’ne ilişkin olarak SİPAY’yi yetkilendirdikten
                          sonra, söz konusu Ödeme İşlemi’nin
                          gerçekleştirilmesine kadar işlemi geri alabilecektir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          SİPAY Ödeme Emri’ni, Ödeme Emri’nin en geç 1 (bir) gün
                          önce verilmesi kaydı ile, KULLANICI ile
                          kararlaştırılan ödeme tarihinde, KULLANICI ile ödeme
                          tarihinin kararlaştırılmadığı hallerde ise en geç,
                          Ödeme Emri’nin SİPAY’ye ulaştığı tarihten itibaren 4
                          (dört) iş günü içerisinde gerçekleştirir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          SİPAY, bir Ödeme Emri’ni yerine getirmeyi reddettiği
                          takdirde, red gerekçesini ve varsa redde sebep olan
                          hataların ne şekilde düzeltilebileceğini yazılı olarak
                          veya KULLANICI’nın işbu Sözleşme’de ve eklerinde
                          belirtmiş olduğu GSM numarasından, e-posta adresinden
                          veya alternatif kanallardan (çağrı merkezi, internet,
                          mobil vs.) biri vasıtasıyla en kısa zamanda ve her
                          halükârda en geç Ödeme Emri’nin alınmasını izleyen iş
                          günü sonuna kadar KULLANICI’ya bildirir. Reddin haklı
                          bir sebebe dayanması halinde KULLANICI, SİPAY’nin
                          bildirimin yapılacağı kanal ile uyumlu olarak ve işbu
                          Sözleşme ekinde yer alan makul bir ücreti tahakkuk
                          ettirme hakkına sahip olduğunu kabul ve beyan eder
                        </li>
                        <li className="legal-text align-justify nested-list">
                          {' '}
                          Ödeme Hizmetleri cep telefonu, bilgisayar gibi bir
                          cihaz ve/veya Uygulama aracılığıyla
                          gerçekleştirilebilecek olup, KULLANICI
                          cihaza/Uygulama’ya ait kullanım koşullarında yer alan
                          teknik ve diğer özelliklere uyumun sağlanmış olması
                          gerekmekte olduğunu kabul ve beyan eder.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          {' '}
                          SİPAY, Ödeme Hizmetleri ile ilgili olarak
                          Yönetmelik’in öngördüğü bilgilendirmeleri
                          KULLANICI’nın işbu Sözleşme’de yer alan e-posta
                          adresi, GSM numarası gibi iletişim bilgilerinden
                          birini ve/veya Uygulama’yı kullanarak işlem sonrasında
                          derhal ya da en geç birer aylık dönemler itibariyle
                          gerçekleştirir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          SİPAY, Ödeme İşlemi’nin Ödeme Emri’ne uygun olarak
                          gerçekleştirilmesinden KULLANICI’ya karşı sorumludur.
                          SİPAY, Ödeme Emri’ni aldığı tarihten itibaren derhal
                          ve her halükârda en geç izleyen iş günü sonuna kadar
                          ödeme işlemi tutarını Alıcı’nın bankasına veya ödeme
                          hizmeti sağlayıcısına aktarmakla; KULLANICI’nın Alıcı
                          olması halinde Ödeme İşlemi tutarını derhal ve en geç
                          ilgili tutarların SİPAY’ye aktarılmasını takip eden iş
                          günü KULLANICI’nın kullanımına hazır hale getirmekle
                          yükümlüdür. Bu süre zarfında ödeme tutarının Alıcı’nın
                          bankasına veya ödeme hizmeti sağlayıcısına
                          gönderilmemesi veya SİPAY tarafından hatalı
                          gönderilmesi halinde SİPAY, gerçekleşmeyen veya hatalı
                          gerçekleşen tutarı gecikmeksizin KULLANICI’ya iade
                          eder veya KULLANICI’nın hesabını eski durumuna
                          getirir. KULLANICI’nın söz konusu işlemin
                          gerçekleşmeme veya hatalı gerçekleşme nedenini
                          SİPAY’den talep etmesi halinde, SİPAY talep edilen
                          bilgiyi KULLANICI’ya bildirmekle yükümlüdür. SİPAY
                          Alıcı’nın bankası veya ödeme hizmeti sağlayıcısının
                          işlemlerinden hiçbir şekilde sorumlu değildir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          SİPAY, Sözleşme kapsamında tahsil edilen tutarlara ve
                          Elektronik Para’ya faiz işletmeyecek, süreye bağlı
                          menfaat sağlamayacak mal veya hizmet alımından
                          kaynaklanan ödeme işleminin gerçekleştirilmesi
                          amacıyla herhangi bir ödeme hesabına bağlı Ödeme Aracı
                          ihracında bulunmayacak, kredi verme, taksitlendirme,
                          tahsil edilemeyen tutarlara ilişkin ödeme veya ödeme
                          garantisi verme anlamına gelecek faaliyetler
                          gerçekleştirmeyecektir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          {' '}
                          SİPAY, (i) sadakat programı ve sair uygulamalar hayata
                          geçirebilir ve (ii) ilgili puan ve kampanyaları
                          herhangi bir neden olmaksızın iptal edebilir,
                          yürürlükten kaldırıp silebilir.
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      ELEKTRONİK PARA’YA İLİŞKİN ÖZEL HÜKÜMLER
                      <ol>
                        <li className="legal-heading-2 align-justify nested-list">
                          KULLANICI’nın Gönderen Olduğu Durumlarda Uygulanacak
                          Hükümler
                          <ol>
                            <li className="legal-text align-justify nested-list">
                              {' '}
                              KULLANICI’nın talebi doğrultusunda, EFT/havale
                              ile, Temsilciler vasıtasıyla, KULLANICI’nın taraf
                              olduğu mesafeli sözleşmeler veya SİPAY tarafından
                              tesis edilen diğer kanallar vasıtasıyla
                              gerçekleştirdiği satın alma işlemleri kapsamında
                              aldığı iadelerin yüklenmesi ile veya kredi/kartı
                              banka kartı vasıtasıyla vb. yöntemlerle talep
                              edilen Elektronik Para ihracı talebini takiben
                              SİPAY, Fon tutarı kadar Elektronik Para ihraç
                              edecek ve bu tutarı KULLANICI’nın kullanımına
                              sunacak ve ihraç etmiş olduğu Elektronik Para
                              karşılığında almış olduğu Fon tutarını gösterir
                              dekontu KULLANICI’ya iletecektir. Kredi kartı ile
                              yapılan Fon yükleme işlemine yönelik SİPAY’a
                              iletilecek harcama itirazı taleplerinde SİPAY
                              ilgili bankanın kendi hesabından mahsup ettiği
                              tutarı KULLANICI’nın hesabından mahsup etme
                              hakkına sahiptir.SİPAY, satın alma işlemlerinin
                              gerçekleştirildiği vasıtaları değiştirme hakkını
                              saklı tutar.
                            </li>
                            <li className="legal-text align-justify nested-list">
                              Aksi KULLANICI tarafından yazılı olarak
                              belirtilmedikçe Elektronik Para’nın hak sahibi,
                              Ödeme Aracı’nın yasal sahibi olarak kabul edilir.
                              Aksi durumlarda KULLANICI’nın SİPAY’ye yazılı
                              olarak bilgi vermesi gerekecektir ve SİPAY ilgili
                              devrin gerçekleşmesi için gerekli kimlik tespiti
                              süreçlerinin tamamlamasını ve ek bilgi temini
                              talep edebilecektir.
                            </li>
                            <li className="legal-text align-justify nested-list">
                              KULLANICI, SİPAY tarafından belirlenecek
                              kanallardan talepte bulunarak Elektronik Para’nın
                              kısmen veya tamamen fona çevrilmesi talimatı
                              verebilir. SİPAY, mevzuatta aksi yönde bir hak
                              veya yükümlülüğü bulunmadığı sürece, KULLANICI’nın
                              bu talebi üzerine IBAN numarası dahil tüm
                              bilgilerin SİPAY’ye eksiksiz olarak iletilmesini
                              ve gerekli olması durumda yasal yükümlülüklerin
                              yerine getirilmesini takiben gecikmeksizin
                              Elektronik Para karşılığı kadar fonun KULLANICI
                              tarafından iletilen IBAN numaralı hesaba
                              aktarımına ilişkin işlemleri gerçekleştirecektir.
                            </li>
                            <li className="legal-text align-justify nested-list">
                              Fona çevirme işlemlerinde, iadeye ilişkin olarak
                              SİPAY tarafından tesis edilen/edilebilecek
                              doğrulama süreçleri işletilecektir. KULLANICI, bu
                              durumda Web Sitesi’nde belirtilen tutarda bir fona
                              çevirme ücretinin kendisine yansıtılabileceğini ve
                              bu ücretin kendisine iletilecek toplam bedelden
                              mahsup edileceğini kabul eder. KULLANICI
                              tarafından iadesi talep edilen tutarın ve/veya
                              aylık bakiye ve/veya kullanım tutarının
                              yürürlükteki mevzuat ile öngörülen limitlerin
                              üzerinde olması halinde KULLANICI, kimlik tespiti
                              yaptırması gerekeceğini, aksi halde bu tespit
                              tamamlanana kadar kendisine bir iade yapılmasının
                              söz konusu olmayacağını kabul eder.
                            </li>
                            <li className="legal-text align-justify nested-list">
                              Aktivasyon işlemi yapılmayan Elektronik Para
                              ürünlerinin kaybolması, çalınması veya üçüncü
                              kişilerce ele geçirilmesinden kaynaklı sorumluluk
                              münhasıran mülkiyeti elinde bulunduran kişiye
                              aittir ve SİPAY’nin bu durumdaki kayıp ve zarardan
                              herhangi bir sorumluluğu bulunmamaktadır.
                              Aktivasyon işlemi esnasında SİPAY, KULLANICI’dan
                              TCKN, Ad, Soyad ve bunlarla sınırlı olmaksızın
                              SİPAY’nin belirleyeceği ve/veya mevzuatın zorunlu
                              tuttuğu kimlik tespitine ilişkin bilgileri talep
                              edebilir ve bu bilgilerin verilmemesi halinde
                              aktivasyon işlemini tamamlamayabilir. Elektronik
                              para, aktivasyon yapılmaksızın paraya çevrilemez,
                              iade edilemez, değiştirilemez.
                            </li>
                          </ol>
                        </li>
                        <li className="legal-heading-2 align-justify nested-list">
                          KULLANICI’nın Alıcı Olduğu Durumlarda Uygulanacak
                          Hükümler
                          <ol>
                            <li className="legal-text align-justify nested-list">
                              KULLANICI, SİPAY’in Gönderen’den aldığı tutara
                              eşit miktarda Elektronik Para ihraç edebileceğini,
                              KULLANICI tarafından gerçekleştirilecek mal
                              ve/veya hizmet alımlarında bu Elektronik Para’nın
                              kullanılabileceğini, Elektronik Para kullanılan
                              hallerde SİPAY’in{' '}
                              <a href="https://sipay.com.tr/limitler-ve-ucretler">
                                https://sipay.com.tr/limitler-ve-ucretler
                              </a>{' '}
                              adresinde alan, Elektronik Para üzerinden yapacağı
                              komisyon, ödeme ve sair kesintilerden geriye kalan
                              Elektronik Para’nın derhal KULLANICI’nın SİPAY
                              nezdindeki ödeme hesabına aktarılacağını kabul ve
                              beyan eder.
                            </li>
                            <li className="legal-text align-justify nested-list">
                              KULLANICI, SİPAY nezdinde tutulan Ödeme Hesabı’na
                              aktarılacak Elektronik Para’nın herhangi bir
                              zamanda kısmen veya tamamen Türk Lirası olarak
                              Fon’a çevrilmesi için SİPAY tarafından
                              belirlenecek kanallardan talepte bulunabilecektir.
                              SİPAY, mevzuat uyarınca aksi yönde bir yükümlülüğü
                              olmaması halinde Elektronik Para’yı ilgili talebi
                              almasını takiben gecikmeksizin Türk Lirası olarak
                              Fon’a çevirerek KULLANICI’nın banka
                              hesabına/kartına gönderir.
                            </li>
                            <li className="legal-text align-justify nested-list">
                              SİPAY, KULLANICI’nın elektronik para hesabında
                              bulunan Elektronik Para’nın belirli bir süre
                              içerisinde kullanılması veya fona çevirmesine
                              ilişkin ilave yükümlülük getirme hakkını saklı
                              tutar. Belirlenecek bu süre sonunda kullanılmayan
                              ve fona çevrilmeyen Elektronik Para, sürenin
                              sonunda herhangi bir talep veya bildirim
                              yükümlülüğü olmaksızın fona çevrilerek
                              KULLANICI’ya aktarılacaktır.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      ELEKTRONİK PARA’YA İLİŞKİN ÖZEL HÜKÜMLER
                      <ol>
                        <li className="legal-heading-2 align-justify nested-list">
                          KULLANICI’nın Gönderen Olduğu Durumlarda Uygulanacak
                          Hükümler
                          <p className="legal-text align-justify nested-list">
                            İşbu Sözleşme kapsamında sağlanan Ödeme Hizmetleri
                            kullanılarak temin edilen mal ve hizmetlere ilişkin
                            ücretler ve bu ücretlerin tahsilatına ilişkin
                            koşulları belirlemeye Alıcı münhasıran yetkili olup
                            KULLANICI, Alıcı ile kendi arasındaki söz konusu
                            hususlarda SİPAY’in herhangi bir sorumluluğunun söz
                            konusu olmadığını kabul eder. Ayrıca KULLANICI,
                            Alıcı tarafından mevzuata uygun şekilde tahakkuk
                            ettirilecek faizlerin de Ödeme Hizmetleri aracılığı
                            ile ödenebileceğini, Alıcı’nın bu yöndeki talimatına
                            istinaden KULLANICI’nın ödeme hesabının ilgili tutar
                            kadar borçlandırılabileceğini kabul eder.
                          </p>
                        </li>
                        <li className="legal-heading-2 align-justify nested-list">
                          Hatalı/Yetkisiz İşlemler
                          <ol>
                            <li className="legal-text align-justify nested-list">
                              KULLANICI; Ödeme Hesabı’nı, Ödeme Aracı’nı ve
                              şifresini, Hassas Ödeme Verileri’ni güvenli bir
                              şekilde korumak ve bu bilgilerin başkaları
                              tarafından kullanılmasına engel olacak tüm
                              önlemleri almakla; Ödeme Aracı’nın hileli ve
                              yetkisiz kullanım şüphesi doğuran bir olayın
                              gerçekleşmesi, Ödeme Aracı’nın kaybolması,
                              çalınması ve iradesi dışında işlem gerçekleştiğini
                              öğrenmesi halinde derhal SİPAY Çağrı Merkezi’ne
                              durumu bildirmek ve Ödeme Aracı’nı kullanıma
                              kapatmakla yükümlüdür. KULLANICI, SİPAY’in
                              herhangi bir kusuru olmaksızın yetkisi olmayan
                              kişiler tarafından gerek ödeme arayüzünün, Ödeme
                              Aracı’nın gerek Hassas Ödeme Verileri’nin gerekse
                              kullanıcı adı ve şifrenin kullanılması ve/veya
                              verilen amaç dışında kullanımı nedeniyle ortaya
                              çıkabilecek zararlara ilişkin olarak işbu
                              Sözleşme’de belirtilen şekilde sorumlu olacağını
                              kabul eder.
                            </li>
                            <li className="legal-text align-justify nested-list">
                              İşbu Sözleşme’nin 6.2.1 maddesinde belirtilen
                              hallerin tespitinin SİPAY tarafından yapılması
                              halinde ise SİPAY, re’sen Ödeme Aracını kullanıma
                              kapatmakla ve KULLANICI’yı kapatma gerekçesi ile
                              ilgili olarak yazılı olarak ya da kalıcı veri
                              saklayıcısı ile bilgilendirmekle yükümlüdür.
                              SİPAY, KULLANICI’nın Ödeme Aracı’nın kullanıma
                              kapatılma sebebinin ortadan kalktığını kendisine
                              bildirmesi halinde Ödeme Aracı’nı kullanıma açar.
                              Bildirimin yapıldığı tarih itibariyle ilgili işlem
                              bedeli Alıcı veya Alıcı’nın ödeme hizmeti
                              sağlayıcısına aktarılmış ise, SİPAY ilgili
                              tutarların Alıcı veya Alıcı’nın ödeme hizmeti
                              sağlayıcısından iadesini talep ederek bu tutarları
                              KULLANICI’ya aktarır. Alıcı veya Alıcı’nın ödeme
                              hizmeti sağlayıcısının iade sürecinden SİPAY
                              sorumlu değildir. KULLANICI, yetkilendirmediği
                              veya hatalı gerçekleştirilmiş ödeme işlemini,
                              öğrendiği tarihten itibaren SİPAY’ye bildirmek ve
                              düzeltme talebinde bulunmakla yükümlüdür. Düzeltme
                              talebi, her halükârda ödeme işleminin
                              gerçekleştirilmesinden itibaren on üç (13) ayı
                              aşamaz. İşlemin, yetkilendirilmeden veya hatalı
                              gerçekleştirildiğinin tespiti halinde SİPAY, söz
                              konusu işleme ilişkin hatalı tutarı derhal
                              KULLANICI’ya iade eder veya KULLANICI’nın hesabını
                              eski duruma getirir. Bildirimin yapıldığı tarih
                              itibariyle ilgili işlem bedeli Alıcı veya
                              Alıcı’nın ödeme hizmeti sağlayıcısına aktarılmış
                              ise, SİPAY ilgili tutarların Alıcı veya Alıcı’nın
                              ödeme hizmeti sağlayıcısından iadesini talep
                              ederek bu tutarları KULLANICI’ya aktarır. İşbu
                              maddede belirtilen 13 (on üç) aylık süre, Tüketici
                              niteliği haiz olmayan KULLANICI’lar için 6 (altı)
                              ay olarak uygulanacaktır.
                            </li>
                            <li className="legal-text align-justify nested-list">
                              Ödeme aracının ve/veya şifre bilgilerinin
                              kaybolması, çalınması veya irade dışında
                              kullanılması halinde KULLANICI, 150 Türk Lirası
                              ile sınırlı olarak sorumludur. KULLANICI, 6.2.1.
                              madde uyarınca yaptığı bildirimden sonra
                              gerçekleşen yetkilendirmediği ödeme işlemlerinden
                              sorumlu olmayacaktır. KULLANICI’nın Ödeme Aracı’nı
                              hileli kullanması ve 6.2.1. maddedeki
                              yükümlülüklerini kasten ya da ağır ihmalle yerine
                              getirmemesi hallerinde KULLANICI, herhangi bir
                              süre ve ücret ile sınırlı olmaksızın, bu sebepten
                              kaynaklanan zararların tamamından sorumludur.
                            </li>
                            <li className="legal-text align-justify nested-list">
                              KULLANICI veya SİPAY, Gönderen’in yetkisiz işlemin
                              varlığına ilişkin haklı bir kanaati oluşursa
                              Gönderen’in yetkilendirmelerini inceleyerek hileli
                              işlem şüphesi halinde ödemeyi kabul
                              etmeyebilecektir. Gönderen’in bu durumda herhangi
                              bir talep hakkı söz konusu olmayacaktır.
                            </li>
                            <li className="legal-text align-justify nested-list">
                              Hatalı ve yetkisiz işlem sebebiyle Alıcı ve/veya
                              Alıcı’nın Ödeme Hizmeti Sağlayıcısı’ndan bedelin
                              geri alınması halinde SİPAY’in işlemden doğan
                              yetkili mahkeme tarafından karara bağlanan
                              zararına ilişkin tutar, herhangi bir izne ve
                              bildirime gerek olmaksızın geri alınan bedelden
                              mahsup edilebilecektir.
                            </li>
                          </ol>
                        </li>
                        <li className="legal-heading-2 align-justify nested-list">
                          Alıcı tarafından başlatılan işlemlerde geri ödeme:
                          <p className="legal-text align-justify">
                            Talep ilgili ödeme işleminin gerçekleştirildiği
                            tarihten itibaren 2 (iki) ay içinde yapılmak
                            şartıyla KULLANICI, Alıcı tarafından
                            yetkilendirilmiş veya Alıcı aracılığıyla başlatılan
                            ödeme işleminde yetkilendirme sırasında ödeme
                            işlemine ilişkin tutarın tam olarak belirtilmemesi
                            ve gerçekleşen ödeme işlemi tutarının, harcama
                            geçmişi, sözleşme şartları ve ilgili diğer hususlar
                            dikkate alınarak öngörülen tutarı aşması halinde,
                            ilgili mal veya hizmetin tüketilmemiş olması kaydı
                            ile ödeme işlemi tutarı kadar geri ödeme yapılmasını
                            talep edebilir. SİPAY bu durumda, KULLANICI’dan
                            talebine ilişkin gerçeğe dayalı kanıtlar sunmasını
                            talep edebilir. Ancak her halde, ödeme işlemine
                            ilişkin onayın doğrudan SİPAY’ye verilmesi veya
                            ödemenin gerçekleştirilmesi gereken tarihten en az 1
                            (bir) ay önce KULLANICI’ya bildirimde bulunulması
                            hallerinde, KULLANICI ilgili ödeme işlemi için
                            herhangi bir geri ödeme talep edemez. İşbu madde
                            uyarınca geri ödeme talebinde bulunulabilen hallerde
                            SİPAY 10 (on) iş günü içerisinde ödemeyi yapacak
                            veya ret kararını gerekçeleri ile birlikte,
                            KULLANICI’nın başvurabileceği hukuki yolları da
                            göstererek KULLANICI’ya bildirecektir.
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      KART SAKLAMA HİZMETLERİ’NE İLİŞKİN HÜKÜMLER
                      <ol>
                        <li className="legal-text align-justify nested-list">
                          KULLANICI’nın Kart Saklama Hizmetleri’nden
                          faydalanması onayına bağlıdır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          KULLANICI, işbu Sözleşme kapsamında, SİPAY aracılığı
                          ile gerçekleştireceği ödeme işlemleri özelindeki onayı
                          dahilinde, ödeme işlemlerinin kolaylaştırılması amacı
                          ile kullanılmak üzere kart bilgilerini SİPAY ile
                          paylaşarak bu bilgilerin KULLANICI tarafından aksi
                          belirtilene kadar SİPAY ya da SİPAY’nin güvenli
                          saklama hizmetleri konusunda hizmet aldığı tedarikçisi
                          nezdinde saklanmasına muvafakat vermektedir.
                          Paylaşılan bu bilgiler; kartın
                        </li>
                        <li className="legal-text align-justify nested-list">
                          {' '}
                          KULLANICI, işbu Sözleşme kapsamında sağlanan
                          bilgilerin kendisine ait olduğunu, doğru ve gerçek
                          olduğunu, söz konusu kartlar ile yapılan tüm
                          işlemlerden tek başına sorumlu olduğunu, bu işlemlere
                          ilişkin olarak her türlü hukuki ve cezai sorumluluğun
                          kendisine ait olduğunu kabul ve taahhüt eder.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          KULLANICI, kart numarası, son kullanma tarihi ve kart
                          güvenlik kodu bilgilerini (talep edilmesi halinde ve
                          talep edilen şekilde) girerek veya Üye İşyeri
                          sayfaları veya diğer uygun mecralar üzerinden
                          entegrasyon vasıtasıyla kart bilgilerinin SİPAY’ye
                          aktarılmasını kabul ederek SİPAY nezdinde kartlarını
                          kaydedebilecektir. Başarılı bir şekilde kart ekleyen
                          KULLANICI, daha sonraki kullanımlarda bir daha ek bir
                          bilgi girmeyi gerektirmeden kartını kullanacaktır. Bu
                          kapsamda; KULLANICI kendi inisiyatifi doğrultusunda
                          SİPAY’in belirlediği limitler dahilinde istediği
                          adette kartı Kart Saklama Hizmetleri kapsamında
                          saklayabilir. KULLANICI, tüm bu kartların saklanması
                          ve bunların kullanımına ilişkin olarak işbu Sözleşme
                          {"'"}nin geçerli olduğunu kabul eder.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          KULLANICI kartı ile ödeme kanallarından birisinde
                          işlem yapmak istediğinde, ilgili ödeme kanalının SİPAY
                          ile entegre olması halinde, kendisine kayıtlı
                          kartlarını ayırt edebileceği bilgiler (KULLANICI
                          tarafından belirlenen kart rumuzu vb.) listelenerek,
                          bu kartları ile işlem yapmak isteyip istemediği
                          sorulacak, isteyen KULLANICI’ların vereceği işlem
                          onayı işlem gerçekleştirilecektir. SİPAY, ileride
                          işlem akışında değişik yapabilir, KULLANICI’nın
                          güvenliği için ek önlemler (şifre, tek seferlik
                          doğrulama kodu kullanımı, Uygulama üzerinden onay vb.)
                          getirebilir. KULLANICI, bu sistem dahilinde
                          belirleyeceği ve kullanacağı tüm şifrelerin
                          güvenliğinden ve gizliliğinden tek başına sorumlu
                          olduğunu, şifrelerini üçüncü kişilerle paylaşmamayı,
                          sisteme tanımladığı kredi kartı ile yapılan tüm
                          işlemlerden tek başına sorumlu olduğunu kabul ve
                          taahhüt eder.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Kullanıcı, SİPAY nezdinde kaydettiği kartını
                          kaybetmesi veya çaldırması durumunda ilgili kart
                          ihracını gerçekleştiren bankası ile iletişime geçmek
                          ve çalınan kartın kapatılmasını sağlamaktan kendisi
                          sorumludur.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          KULLANICI, Kart Saklama Hizmetleri’nin kullanımı
                          sırasında işbu Sözleşme ve yürürlükteki mevzuata uygun
                          hareket etmekle yükümlüdür. SİPAY nezdinde, Kullanıcı
                          tarafında herhangi bir aykırılık şüphesi söz konusu
                          olduğu takdirde, SİPAY ilgili hizmetin sunumunu derhal
                          askıya alabilecek veya işbu Sözleşme’yi feshederek
                          sonlandırabilecektir. Kullanıcı, işbu Sözleşme veya
                          mevzuata aykırılığı neticesinde SİPAY nezdinde meydana
                          gelen tüm zararları tazmin edecektir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          SİPAY, yürürlükteki mevzuata uyumu sağlamak adına
                          herhangi bir zamanda KULLANICI{"'"}dan ek bilgi, belge
                          veya onay talep edebilecek olup, KULLANICI bu
                          talepleri yerine getireceğini, aksi takdirde Kart
                          Saklama Hizmetleri’nin işbu Sözleşme kapsamında
                          belirtildiği şekilde verilemeyebileceğini kabul eder.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          İşbu maddenin konusu Kart Saklama Hizmetleri SİPAY
                          tarafından ücretsiz olarak sunulabileceği gibi, zaman
                          zaman çeşitli kampanyalara konu edilebilecek veya
                          ücrete tabi bir şekilde sunulabilecektir. KULLANICI,
                          Hizmet’i kullandığı müddetçe SİPAY’nin ücretlendirme
                          politikası ile bağlı olduğunu kabul eder.
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      MALİ HÜKÜMLER
                      <ol>
                        <li className="legal-text align-justify nested-list">
                          Ödeme Hizmeti karşılığında KULLANICI tarafından
                          ödenmesi gereken ücretler olması halinde, hizmet
                          nevilerine göre sınıflandırılmış şekilde{' '}
                          <a href="https://sipay.com.tr/limitler-ve-ucretler">
                            https://sipay.com.tr/limitler-ve-ucretler
                          </a>{' '}
                          adresinde, Web Sitesi’nde ve/veya Uygulama’da yer
                          alacaktır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Web sitesinde ve/veya Uygulama’da belirtilecek ücret,
                          masraf ve komisyonlar SİPAY tarafından tek taraflı
                          olarak güncellenebilecek olup, KULLANICI işbu Sözleşme
                          uyarınca kendisine yapılan bildirimi müteakip söz
                          konusu değişiklik nedeniyle Sözleşme’yi feshetmediği
                          takdirde hizmeti kullandığı sürece güncel hizmet
                          bedelinin yukarıda belirtilen şekilde tahsil
                          edileceğini kabul eder.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          SİPAY’in mevzuat kapsamında yapması gereken
                          bilgilendirmelere ek olarak ek bilgi, daha sık
                          bilgilendirme veya bilgilendirmenin işbu Sözleşme’de
                          öngörülenden farklı yöntemlerle iletilmesinin talep
                          edilmesi halinde, Web Sitesi’nde ve/veya Uygulama’da
                          belirtilen ücretlendirme tarifesi uygulanır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          SİPAY, KULLANICI’nın ihmal veya kusuru gibi Ödeme
                          Emri’nin haklı sebeple reddi durumunda KULLANICI’ya
                          yapacağı bildirim, Ödeme Emri’nin geri alınmasının
                          talep edilmesi hali, hatalı Ödeme İşlemi’ne konu
                          Fon’ları geri alınması ve sair durumlar için Web
                          Sitesi’nde ve/veya Uygulama’da belirtilen ücreti talep
                          etme hakkını saklı tutar.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Ödeme Hizmetleri kapsamında gerçekleştirilecek
                          ücretlendirmeye tabi olan işbu Sözleşme’de yer
                          alamayan diğer işlemler SİPAY tarafından belirlenmiş
                          ve Web Sitesi’nde ve/veya Uygulama’da belirtilen işlem
                          ücretlerine tabi olabilecektir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          SİPAY’ye bildirilen banka hesabının güncel, açık ve
                          kullanılır durumda olması KULLANICI’nın
                          sorumluluğundadır. Bildirilen banka hesabının,
                          KULLANICI adına kayıtlı olmaması halinde ise bu hesaba
                          SİPAY tarafından ödeme yapılamayacaktır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          SİPAY, işbu Sözleşme kapsamında KULLANICI’dan olan
                          alacaklarını herhangi bir bildirimde bulunmaksızın
                          KULLANICI’ya yapılacak ödemelerden mahsup edebilir.
                          KULLANICI bu hususta herhangi bir itiraz ve talepte
                          bulunmayacağını peşinen kabul, beyan ve taahhüt eder.
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      SÖZLEŞME’NİN SÜRESİ VE FESİH
                      <ol>
                        <li className="legal-text align-justify nested-list">
                          Sözleşme Kullanıcı tarafından onaylandığı veya
                          imzalandığı tarihte yürürlüğe girer ve feshedilmediği
                          sürece yürürlükte kalır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme, her zaman Taraflar’ın karşılıklı yazılı
                          mutabakatı ile feshedilebilecektir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          SİPAY, herhangi bir zamanda 2 (iki) ay öncesinde
                          yapacağı yazılı bildirimle; KULLANICI ise, 1 (bir) ay
                          öncesinde yapacağı yazılı bildirimle Sözleşme’yi
                          herhangi bir tazminat ödemeksizin feshedebilecektir.
                          Ödeme Hizmetleri’nin ve Ödeme Hesabı’nın mevzuat ve
                          ahlaka aykırı amaçlarla ve/veya mevzuat ve ahlaka
                          aykırı mal ve hizmetlerin temini için kullanıldığının
                          tespiti halinde SİPAY, işbu Sözleşme’yi derhal
                          feshedebilecek olup, KULLANICI’yı fesih hususunda
                          bilgilendirebilecektir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          SİPAY’in ihmali veya kusurundan kaynaklanan bir
                          sebeple olmaması şartıyla SİPAY’in Kurum’dan temin
                          ettiği lisansın iptali veya sona ermesi halinde işbu
                          Sözleşme herhangi bir tazminat yükümlülüğü söz konusu
                          olmaksızın kendiliğinden sona erecektir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme kapsamında KULLANICI’nın SİPAY’in
                          güvensiz ve/veya şüpheli addettiği mecralarda Ödeme
                          Hizmetleri’ni kullanması halinde, hileli veya yetkisiz
                          kullanım şüphesinin ve benzeri durumların söz konusu
                          olduğu hallerde SİPAY, KULLANICI’ya yapacağı
                          bildirimle KULLANICI’ya sunduğu hizmetleri askıya
                          alabilecek, Ödeme Hesabı’na geçici veya süresiz olarak
                          bloke koyabilecek, Ödeme Hesabını ve/veya Ödeme
                          Aracı’nı geçici veya süresiz olarak kullanıma
                          kapatabilecektir. Bu durumda SİPAY mevzuatta bilgi
                          verilmesini engelleyici düzenlemeler bulunması veya
                          güvenliği tehdit edici objektif nedenlerin varlığı
                          dışında KULLANICI’yı bilgilendirecek ve askıya alınma
                          sebebi ortadan kalktığında hizmetleri ve Ödeme
                          Aracı’nı yeniden KULLANICI kullanımına sunacaktır.
                        </li>
                      </ol>
                    </li>
                    <li className="legal-heading-2">
                      MUHTELİF HÜKÜMLER
                      <ol>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme’de belirtilenlerin dışında, SİPAY’in,
                          Ödeme Hizmetleri’ne ilişkin koşullarda, Web Sitesi ve
                          Uygulama’daki içeriklerde, Sözleşme ve eklerinde tek
                          taraflı olarak değişiklik ve güncelleme yapma hakkı
                          mevcuttur. Söz konusu değişiklikler yahut mevzuat ile
                          belirlenen yükümlülükler kapsamında yapılacak
                          değişiklikler ve Ödeme Hizmetleri’nin sunulması için
                          SİPAY’in anlaşma yaptığı Ödeme Hizmeti Sağlayıcısı
                          konumundaki üçüncü kişiler ile akdedilen sözleşmeler
                          veya bu sözleşme hükümleri kapsamında yapılacak
                          değişiklikler işbu Sözleşme koşullarında bir
                          değişikliğe sebep olacak ise, KULLANICI’ya ilgili
                          değişikliğin yürürlüğe girmesinden 30 (otuz) gün
                          öncesinde değişikliğin kapsamı, yürürlük tarihi ve
                          KULLANICI’nın Sözleşme’yi herhangi bir ücret
                          ödemeksizin feshedebileceğine ilişkin bilgileri içeren
                          bir bildirim yapılacaktır. Bu durumda değişikliği
                          kabul etmek istemeyen KULLANICI işbu Sözleşme’yi
                          feshedebilecektir. KULLANICI’nın, kendisine bildirim
                          yapılmasını müteakip 30 (otuz) gün içinde fesih
                          bildiriminde bulunmaması halinde, ilgili değişiklik
                          kabul edilmiş sayılacaktır. Mevzuat kapsamında gereken
                          herhangi bir değişiklik için ilgili mevzuatta 30
                          (otuz) günden daha kısa süre verilmesi halinde bu
                          husus da KULLANICI’ya iletilecek bildirimde
                          belirtilecek ve işbu maddede belirtilen süre yerine
                          mevzuatın öngördüğü süre uygulanacaktır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          SİPAY, KULLANICI’nın Ödeme Hizmeti ile ilgili şikayet
                          ve itiraz başvurularını, başvuru tarihini takiben 20
                          (yirmi) gün içinde başvuru yöntemine uygun olarak
                          gerekçeli biçimde cevaplandırır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme’den kaynaklı uyuşmazlıklarda İstanbul
                          Anadolu (Kartal) Mahkemeleri ve İcra Müdürlükleri
                          yetkili kılınmıştır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          KULLANICI’nın işbu Sözleşme kapsamındaki hizmetleri
                          mesleki ve ticari amaçlarla satın aldığı hallerde,
                          KULLANICI Sözleşme’yi onaylamış olsa dahi işbu
                          Sözleşme’nin Tüketicilere ilişkin hükümleri
                          KULLANICI’ya uygulanmayacaktır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Tarafların kontrolü dışında ortaya çıkan,
                          öngörülemeyen ve işbu Sözleşme uyarınca Tarafların
                          yükümlülüklerini yerine getirmesini engelleyecek
                          olağanüstü hal, seferberlik, deprem, grev-lokavt,
                          yakıt, nakliye, ekipman veya sair mallar ve
                          hizmetlerin yokluğu, doğal afetler, bulaşıcı
                          hastalıklar, ihracat veya ithalat kısıtlamaları,
                          yangın, infilak, kazalar, sabotaj, halk ayaklanmalar,
                          SİPAY’den kaynaklanmayan sebeplerle SİPAY’in faaliyet
                          izninin geçici iptali vb. mücbir sebep hallerinden
                          doğan gecikmelerden Taraflar sorumlu tutulamaz. Mücbir
                          sebebe maruz kalan Taraf durumu derhal diğer Taraf’a
                          yazılı olarak bildirir ve mücbir sebep süresi boyunca
                          Taraflar’ın edimleri askıya alınır. Mücbir sebepler
                          ortadan kalkınca Sözleşme kaldığı yerden devam eder.
                          Mücbir sebep süresi boyunca hakları ifa edilmeyen
                          Taraf’ın yükümlülükleri de askıya alınacaktır. Mücbir
                          sebep halinin 15 günden fazla sürmesi halinde, hakları
                          ihlal edilen Taraf Sözleşme’yi tazminatsız olarak
                          feshedebilir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Sözleşme veya yürürlükteki mevzuatta aksi
                          belirtilmedikçe Sözleşme uyarınca yapılacak tüm
                          bildirimler yazılı veya elektronik olarak
                          iletilebilir. SİPAY, işbu Sözleşme kapsamında
                          KULLANICI’ya yönelik yapacağı bildirimleri Sözleşme’de
                          belirtilen zamanlarda KULLANICI’nın belirtilen
                          adresine iletilecek e-posta aracılığıyla yapacaktır.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          Taraflarca belirtilen adresler Taraflar’ın yasal
                          tebligat adresleri olup, herhangi bir adres
                          değişikliği karşı tarafa yazılı olarak bildirilmediği
                          sürece bu adreslere yapılan tebligatlar geçerli
                          addedilecektir.
                        </li>
                        <li className="legal-text align-justify nested-list">
                          İşbu Sözleşme’nin bir örneği, Sözleşme’nin yapılmasını
                          takiben yazılı olarak veya kalıcı veri saklayıcısı ile
                          KULLANICI’ya verilir veya KULLANICI’nın erişimine
                          hazır bulundurulur. KULLANICI’nın talep etmesi
                          halinde, Sözleşme ve eklerinin bir başka sureti ve
                          yapılan işlemlere ilişkin belgeler Web Sitesi’nde
                          belirtilen ücretler karşılığında verilir.
                          <br />
                          <br />
                          10 (on) maddeden oluşan işbu Sözleşme Taraflarca kabul
                          edilmiştir.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
