import React from 'react'
import appdownload from '../img/appdownload.webp'

export const AppDownloadModal = (openModal) => {
  if (!openModal) return null
  return (
    <>
      <div
        className="modal fade"
        id="appModal"
        aria-labelledby="appModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-12 mx-auto">
                <img className="h-100 w-100" src={appdownload} alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
