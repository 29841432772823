import React from 'react'

export const AboutModal = ({ openModal, data }) => {
  if (!openModal) return null
  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row modal-padding-left-right">
                <div className="col-md-4 col-sm-12">
                  <img
                    className="ml-10 mx-auto text-center card-img-top"
                    src={data.imgName}
                    alt=""
                  ></img>
                </div>
                <div className="col-md-8 col-sm-12 mx-auto">
                  <h5 className="card-title about-card-title modal-title">
                    {data.fullName}
                  </h5>
                  <p
                    className="modal-text"
                    dangerouslySetInnerHTML={{ __html: data.content }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </>
  )
}
