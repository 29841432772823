import React from 'react'

export const ApplicationForm = () => {
  return (
    <>
      <section className="legalinformation">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="mt-75 mb-120">
                <p className="legal-heading text-center ">
                  MUDİ FİNANSAL TEKNOLOJİ HİZMETLERİ ANONİM ŞİRKETİ
                  <br />
                  KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA BAŞVURU FORMU
                </p>
                <p className="legal-text align-justify">
                  6698 sayılı Kişisel Verilerin Korunması Kanunu’nun(“Kanun”)
                  11. maddesi uyarınca Şirketimize başvurarak;
                  <br />
                  <ol type="1" className="list-text">
                    <li>
                      Kişisel verilerinizin işlenip işlenmediğini öğrenme,
                    </li>
                    <li>
                      Kişisel verileriniz işlenmişse buna ilişkin bilgi talep
                      etme,
                    </li>
                    <li>
                      Kişisel verilerinizin işlenme amacını ve bunların amacına
                      uygun kullanılıp kullanılmadığını öğrenme,
                    </li>
                    <li>
                      Yurt içinde veya yurt dışında Kişisel Verilerinizin
                      aktarıldığı üçüncü kişileri bilme,
                    </li>
                    <li>
                      Kişisel verilerinizin eksik veya yanlış işlenmiş olması
                      hâlinde bunların düzeltilmesini isteme,
                    </li>
                    <li>
                      Amaç, süre ve meşruiyet prensipleri dâhilinde
                      değerlendirilmek üzere Kişisel Verilerinizin işlenmesini
                      gerektiren sebeplerin ortadan kalkması halinde silinmesini
                      veya yok edilmesini isteme,
                    </li>
                    <li>
                      Kişisel verilerinizin düzeltilmesi, silinmesi ya da yok
                      edilmesi halinde bu işlemlerin Kişisel Verilerin
                      aktarıldığı üçüncü kişilere bildirilmesini isteme,
                    </li>
                    <li>
                      İşlenen Kişisel Verilerinizin münhasıran otomatik
                      sistemler vasıtasıyla analiz edilmesi durumunda aleyhinize
                      bir sonucun ortaya çıkması halinde bu sonuca itiraz etme,
                    </li>
                    <li>
                      Kişisel Verilerinizin kanuna aykırı olarak işlenmesi ve bu
                      sebeple zarara uğramanız hâlinde zararın giderilmesini
                      talep etme haklarına sahipsiniz.
                    </li>
                  </ol>
                  İlgili kişilerin, Kanun’un 13’üncü maddesinin 1’inci fıkrası
                  gereğince haklarını kullanmakla ilgili taleplerini, aşağıdaki
                  yöntemlerle Şirketimize iletmesi gerekli ve yeterlidir;
                </p>
                <table className="table-2">
                  <tr>
                    <td>Başvuru Yöntemi</td>
                    <td>
                      <b>Başvurunun Yapılacağı Adres</b>
                    </td>
                    <td>
                      <b>Başvuru Gönderiminde Belirtilecek Bilgi</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Şahsen başvuru</b> (Başvuru sahibinin bizzat gelerek
                      kimliğini tevsik edici belge ile başvurması)
                    </td>
                    <td>
                      Levazım Mah, Koru Sok. Zorlu Center No:2 İç Kapı No:21
                      Beşiktaş/İstanbul
                    </td>
                    <td>
                      Zarfın üzerine “Kişisel Verilerin Korunması Kanunu
                      Kapsamında Bilgi Talebi” yazılacaktır
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Noter vasıtasıyla tebligat</b>
                    </td>
                    <td>
                      LevazımMah, Koru Sok. Zorlu Center No:2 İç Kapı No:21
                      Beşiktaş/İstanbul
                    </td>
                    <td>
                      Tebligat zarfına “Kişisel Verilerin Korunması Kanunu
                      Kapsamında Bilgi Talebi” yazılacaktır
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>“Güvenli elektronik imza”</b> ile imzalanarak Kayıtlı
                      Elektronik Posta (KEP) yoluyla
                    </td>
                    <td>
                      <a
                        href="mailto:nemateknoloji@hs01.kep.tr"
                        className="font-size-8"
                      >
                        nemateknoloji@hs01.kep.tr
                      </a>
                    </td>
                    <td>
                      E-postanın konu kısmına “Kişisel Verilerin Korunması
                      Kanunu Bilgi Talebi” yazılacaktır
                    </td>
                  </tr>
                </table>
                <br />
                <ul className="list-text legal-text list-style-circle">
                  <li>
                    Başvuruda, ad, soyadı ve başvuru yazılı ise imza, Türkiye
                    Cumhuriyeti Vatandaşları İçin T.C. Kimlik Numarası,
                    yabancılar için uyruğu, pasaport numarası veya varsa kimlik
                    numarası, tebligata esas yerleşim yeri veya işyeri adresi,
                    varsa bildirime esas elektronik posta adresi, telefon
                    numarası, talep konusu, bulunması zorunludur. Konuya ilişkin
                    bilgi ve belgeler de başvuruya eklenir.
                  </li>
                  <li>
                    Kişisel verisi işlenen “İlgili kişi” olarak sahip olduğunuz
                    ve yukarıda belirtilen haklarınızı kullanmak için
                    yapacağınız ve kullanmayı talep ettiğiniz hakka ilişkin
                    açıklamalarınızı içeren başvuruda; talep ettiğiniz hususun
                    açık ve anlaşılır olması, talep ettiğiniz konunun şahsınız
                    ile ilgili olması veya başkası adına hareket ediyor iseniz
                    yetkili olmanız ve yetkinizin belgelendirilmesi, başvurunun
                    kimlik ve adres bilgilerini içermesi ve başvuruya
                    kimliğinizi tevsik edici belgelerin eklenmesi gerekmektedir.
                  </li>
                  <li>
                    Kişisel verisi işlenen “İlgili kişi” olarak sahip olduğunuz
                    ve yukarıda belirtilen haklarınızı kullanmak için
                    yapacağınız ve kullanmayı talep ettiğiniz hakka ilişkin
                    açıklamalarınızı içeren başvuruda; talep ettiğiniz hususun
                    açık ve anlaşılır olması, talep ettiğiniz konunun şahsınız
                    ile ilgili olması veya başkası adına hareket ediyor iseniz
                    yetkili olmanız ve yetkinizin belgelendirilmesi, başvurunun
                    kimlik ve adres bilgilerini içermesi ve başvuruya
                    kimliğinizi tevsik edici belgelerin eklenmesi gerekmektedir.
                  </li>
                  <li>
                    Kişisel verilerinizin güvenliğini sağlamak amacıyla bilgi
                    edinme başvurunuzun ulaşmasının ardından Şirketimiz, İlgili
                    kişi olduğunuzu teyit etmek amacıyla sizinle iletişime
                    geçebilecek, bu hususta sizlerden bazı belge ve bilgiler
                    talep edebilecektir.
                  </li>
                </ul>
                <p className="legal-heading-2 text-center">
                  BAŞVURU SAHİBİ TALEP DETAYI
                </p>
                <p className="legal-text align-justify">
                  Kişisel Verilerin Korunması Kanunu uyarınca yukarıda sayılan
                  haklarınız kapsamında, talebinizi aşağıdaki forma
                  belirtebilirsiniz.
                </p>
                <p className="legal-heading-2 text-center">
                  İLGİLİ KİŞİ BAŞVURU
                  <br />
                  FORMU
                </p>

                <table className="table-6">
                  <tr>
                    <td colSpan={6}>
                      <b>Bölüm I – İlgili Kişinin Bilgileri</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>Soyadı:</td>
                    <td colSpan={3}>Ad:</td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      T.C. Kimlik Numarası, (yabancılar için uyruğu, pasaport
                      numarası veya varsa kimlik numarası):
                      <br />
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={6}>Adres:</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Şehir:</td>
                    <td colSpan={2}>İlçe:</td>
                    <td colSpan={2}>Posta Kodu:</td>
                  </tr>
                  <tr>
                    <td colSpan={3}>Ülke:</td>
                    <td colSpan={3}>Telefon Numarası:</td>
                  </tr>
                  <tr>
                    <td colSpan={6}>E-posta veya KEP Adresi:</td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      <b>
                        Bölüm II –Talep Konusu: Lütfen bu kısmı mümkün olduğunca
                        detaylı doldurunuz.
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      Talep Konusu Hakkında Özet:
                      <br />
                      <br />
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>Meydana gelme tarihi (biliniyorsa):</td>
                    <td colSpan={3}>Meydana geldiği yer (biliniyorsa):</td>
                  </tr>
                  <tr>
                    <td colSpan={6}>Dahil olan kişiler (biliniyorsa):</td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      <b>
                        Bölüm III- Beyan (Yanıtın tarafınıza bildirilme
                        yöntemini X şeklinde işaretleyerek seçiniz)
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Posta ile gönderilmesini:
                      <br />
                      <br />
                    </td>
                    <td colSpan={2}>
                      E-posta /KEP adresime gönderilmesini:
                      <br />
                      <br />
                    </td>
                    <td colSpan={2}>
                      Elden teslim edilmesini:
                      <br />
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>İmza:</td>
                    <td colSpan={3}>Tarih:</td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      <b>Bölüm IV – Şirket Tarafından Doldurulacak Kısım</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Talep No:
                      <br />
                      <br />
                      <br />
                    </td>
                    <td colSpan={2}>
                      Şirket İçi KVKK Ekibine aktarıldı (E/H):
                      <br />
                      <br />
                      <br />
                    </td>
                    <td colSpan={2}>
                      Şirket İçi İlgili Departman(lar):
                      <br />
                      <br />
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>Çözüm :</td>
                    <td colSpan={3}>Çözüm Tarihi:</td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      Çözüm Özeti:
                      <br />
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      Ekler:
                      <br />
                      <br />
                    </td>
                  </tr>
                  <tr className="d-none">
                    <td className="table-ratio"></td>
                    <td className="table-ratio"></td>
                    <td className="table-ratio"></td>
                    <td className="table-ratio"></td>
                    <td className="table-ratio"></td>
                    <td className="table-ratio"></td>
                  </tr>
                </table>
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
