import React, { useEffect, useState } from 'react'
import api from '../utils/axios'

export const RatesModal = () => {
  const [rates, setRates] = useState([
    {
      id: '',
      rate: '',
      currency: '',
      maxDayCount: '',
      minDayCount: '',
    },
  ])

  useEffect(() => {
    ratesFunction()
  }, [])

  const ratesFunction = async () => {
    const data = {
      date: new Date().toISOString(),
    }

    await api.gateway
      .post('/v1/unlu/rates', JSON.stringify(data))
      .then((response) => {
        return setRates(response.data)
      })
      .catch((error) => {
        return error
      })
  }

  let index = 0

  return (
    <>
      <div
        className="modal fade"
        id="ratesModal"
        aria-labelledby="ratesModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="container">
                  <div className="row">
                    <div className="justify-content-center mx-auto">
                      <div className="product-table-title text-center">
                        {' '}
                        Güncel Nema Oranları
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mt-2 d-flex justify-content-center">
                      <div className="col-sm-10">
                        <div className="ratio-info">
                          <span>
                            Sermaye Piyasası ürünlerinde değerlendirdiğin
                            birikimlerinden kalan bakiyen için bugün
                            ulaşabileceğin nema oranlarını aşağıda bulabilirsin.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-25 mb-20 justify-content-center mx-auto">
                    <div className="col-sm-12 mx-auto mb-30">
                      <ul
                        className="nav nav-pills justify-content-center product-table-nav mx-auto"
                        id="pills-tab1"
                        role="tablist"
                      >
                        <li
                          className="nav-item table-nav-item"
                          role="presentation"
                        >
                          <button
                            className="nav-link table-nav active w-100"
                            id="pills-home-tab1"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home1"
                            type="button"
                            role="tab"
                            aria-controls="pills-home1"
                            aria-selected="true"
                          >
                            <span className="product-table-nav-text-popup">
                              Türk Lirası
                            </span>
                          </button>
                        </li>
                        <li
                          className="nav-item table-nav-item"
                          role="presentation"
                        >
                          <button
                            className="nav-link table-nav w-100"
                            id="pills-profile-tab1"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile1"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile1"
                            aria-selected="false"
                          >
                            <span className="product-table-nav-text-popup">
                              Amerikan Doları
                            </span>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="col-sm-10 tab-content product-tab-content-padding"
                      id="pills-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="pills-home1"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab1"
                      >
                        <div className="col-md-12 col-sm-12 mt-25 table-responsive-sm">
                          <table className="table table-properties product-table-popup align-middle">
                            <thead className="align-middle">
                              <tr className="product-table-bg">
                                <th className="product-table-header">
                                  <div className="product-table-header-text-popup-start text-start">
                                    Vade
                                  </div>
                                </th>
                                <th className="product-table-header">
                                  <div className="product-table-header-text-popup text-end padding-end">
                                    Nema Oranı
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {rates
                                .filter((filter) => filter.currency == 'TRY')
                                .map((item) => (
                                  <tr key={index++}>
                                    <td key={index++}>
                                      <div className="product-table-content-text-popup">
                                        {Number(item.maxDayCount) == 1
                                          ? 'Gecelik'
                                          : item.minDayCount ===
                                            item.maxDayCount
                                          ? `${item.maxDayCount} gün`
                                          : `${item.minDayCount}-${item.maxDayCount} gün`}
                                      </div>
                                    </td>
                                    <td key={index++}>
                                      <div className="col-sm-10 rates-table-content-text table-td-color text-end">
                                        %
                                        {(Number(item.rate) * 100)
                                          .toFixed(2)
                                          .replace('.', ',')}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile1"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab1"
                      >
                        <div className="col-md-12 col-sm-12 mt-25 table-responsive-sm">
                          <table className="table table-properties product-table-popup align-middle">
                            <thead className="align-middle">
                              <tr className="product-table-bg">
                                <th className="product-table-header">
                                  <div className="product-table-header-text-popup-start text-start">
                                    Vade
                                  </div>
                                </th>
                                <th className="product-table-header">
                                  <div className="product-table-header-text-popup text-end padding-end">
                                    Nema Oranı
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {rates
                                .filter((filter) => filter.currency == 'USD')
                                .map((item) => (
                                  <tr key={index++}>
                                    <td key={index++}>
                                      <div className="product-table-content-text-popup">
                                        {Number(item.maxDayCount) == 1
                                          ? 'Gecelik'
                                          : item.minDayCount ===
                                            item.maxDayCount
                                          ? `${item.maxDayCount} gün`
                                          : `${item.minDayCount}-${item.maxDayCount} gün`}
                                      </div>
                                    </td>
                                    <td key={index++}>
                                      <div className="rates-table-content-text table-td-color text-end padding-end">
                                        %
                                        {(Number(item.rate) * 100)
                                          .toFixed(2)
                                          .replace('.', ',')}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-10 spk-text">
                      Bu hizmet Sermaye Piyasası Kurulu (SPK) lisanslı aracı
                      kurum partneri ile sağlanır.
                    </div>
                    <a
                      className="standart-button fullsize-button nounderline text-center order-sm-1 order-md-3 d-sm-none mt-30"
                      href="https://app.adjust.com/10qkthqm?campaign=mobile"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Uygulamayı İndir
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
