import axios from 'axios'

const gateway = axios.create({
  baseURL: 'https://api.getmundi.app',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':
      'Origin, X-Requested-With, Content-Type, Accept, Authorization',
  },
})

export default { gateway }
