import React from 'react'
import unluLogo from '../img/UnluLogo.webp'

export const BussinessPartners = () => {
  return (
    <>
      <div className="container">
        <div className="row mx-auto">
          <div className="row mx-auto">
            <div className="row mx-auto">
              <div className="col mx-auto">
                <h2 className="about-team-title mb-0">İş Ortaklarımız</h2>
                <div className="about-team-message">
                  Sana en iyi hizmeti sunabilmek için güvenilir iş ortaklarıyla
                  çalışıyoruz.
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-auto mt-25 mb-120-collapse-16">
            <div className="col-sm-12">
              <div className="card text-center about-card-body h-100 py-4 px-3">
                <div className="row mx-auto">
                  <img
                    className="about-business-logo m-sm-3"
                    src={unluLogo}
                    alt="mundi"
                  ></img>
                </div>
                <div className="row mx-auto">
                  <div className="card-body about-card-frame">
                    <div className="card-title about-card-title">
                      Aracı Kurum
                    </div>
                    <p className="card-text about-business-text text-center m-sm-3">
                      Mundi üzerinden kullandığın yatırım hesabın Yatırım
                      Kuruluşları Tebliği’ne ve ilgili mevzuata uygun şekilde
                      Ünlü Menkul Değerler A.Ş. bünyesinde açılır.
                      <br />
                      <br className="d-block d-sm-none" />
                      Ünlü Menkul Değerler A.Ş, Sermaye Piyasası Kurulu (SPK) ve
                      Mali Suçları Araştırma Kurulu (MASAK) denetimine tabi bir
                      geniş kapsamlı aracı kurumdur.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
