import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import swal from 'sweetalert2'
import api from '../utils/axios'
import { Faq } from './faq'
import messageIcon from '../img/messageicon.webp'
import { PermissionModal } from './permissionmodal'
import { Meta } from '../meta'
import { joiResolver } from '@hookform/resolvers/joi'
import { TicketModel } from '../model'
import validator from '../validation'

export const Support = () => {
  const metaTitle = 'Mundi Destek - Soru ve Önerilerin İçin Bize Ulaş'
  const metaDescription =
    'Destek talebi oluşturabilir, 0850 242 24 24 numaralı telefondan bize ulaşabilir veya uygulamamızdaki Yardım Merkezi sayfasını ziyaret edebilirsin.'
  Meta(metaTitle, metaDescription)

  const campaign = useRef<HTMLInputElement>(null)

  const [getTicketFields, setgetTicketFields] = useState([
    {
      id: '',
      name: '',
      value: '',
    },
  ])

  const [selectedIndex, setSelectedIndex] = useState(-1)

  useEffect(() => {
    getTicketFieldList()
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TicketModel>({
    resolver: joiResolver(validator.ticketSchema),
  })

  const onChangeSelect = (e) => {
    e.preventDefault()
    setSelectedIndex(e.target.selectedIndex - 1)
  }

  const onSubmit = async (data, e) => {
    const fieldData = selectedIndex >= 0 ? getTicketFields[selectedIndex] : null

    const model = {
      body: data.message,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      subject: fieldData ? fieldData.name : '',
      fieldId: fieldData ? fieldData.id : 0,
      fieldValue: fieldData ? fieldData.value : '',
    }

    await api.gateway
      .post('/v1/crm/addTicket', JSON.stringify(model))
      .then((response) => {
        if (response.status === 200) {
          e.target.reset()
          return swal.fire({
            title: 'Mesajını aldık!',
            text: 'Kısa süre içerisinde seninle iletişime geçeceğiz.',
            imageUrl: `${messageIcon}`,
            imageWidth: 300,
            confirmButtonText: 'Tamam',
          })
        }
        return swal.fire({
          imageUrl: `${messageIcon}`,
          imageWidth: 300,
          title: 'Bir hata oluştu!',
          confirmButtonText: 'Tamam',
        })
      })
      .catch((error) => {
        if (
          error.response.status === 422 &&
          error.response.data.error.details[0].message
        ) {
          e.target.reset()
          return swal.fire({
            imageUrl: `${messageIcon}`,
            imageWidth: 300,
            title: `${error.response.data.error.details[0].message}`,
            confirmButtonText: 'Tamam',
          })
        }

        return swal.fire({
          imageUrl: `${messageIcon}`,
          imageWidth: 300,
          title: 'Bir hata oluştu!',
          confirmButtonText: 'Tamam',
        })
      })
  }

  const getTicketFieldList = async () => {
    await api.gateway
      .get('/v1/crm/getTicketFields')
      .then((response) => {
        return setgetTicketFields(response.data)
      })
      .catch(() => {
        return setgetTicketFields([])
      })
  }

  const [openModal, setOpenModal] = useState(false)
  const [permissionKey, setPermissionKey] = useState(0)

  const handleClick = (event, key) => {
    event.preventDefault()
    setOpenModal(true)
    setPermissionKey(key)
  }

  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h1 className="support-header support-header-title mb-0">
                Mundi’yi merak ediyorsan anlatalım
              </h1>
              <p className="support-text mb-120 text-center mt-20">
                Mundi ile ilgili her şeyin açık ve anlaşılır olmasını istiyoruz.
                Aklına takılanlar varsa <br className="d-none d-sm-block" />{' '}
                sıkça sorulan sorulara göz atabilir veya bizimle iletişime
                geçebilirsin.
              </p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section id="SSS">
        <Faq />
      </section>
      <section id="SupportForm" className="bg1">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 mx-auto text-center">
              <h2 className="support-header mb-0">
                Yardımcı olmak bizi mutlu eder
              </h2>
              <p className="support-text text-center">
                Lütfen aklına takılan her türlü soru, yorum ve görüşü bizimle
                paylaş.
                <br className="d-none d-sm-block" />
                Sana en kısa zamanda dönüş yapalım.
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="row mx-auto">
              <div className="col-sm-8 justify-content-center offset-sm-2">
                <div className="mb-3">
                  <label htmlFor="firstName" className="form-label">
                    AD
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    {...register('firstName', { required: true })}
                  />
                </div>
                {errors.firstName && (
                  <p>
                    <span className="error">Bu alan zorunludur.</span>
                  </p>
                )}
                <div className="mb-3">
                  <label htmlFor="lastName" className="form-label">
                    SOYAD
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    {...register('lastName', { required: true })}
                  />
                </div>
                {errors.lastName && (
                  <p>
                    <span className="error">Bu alan zorunludur.</span>
                  </p>
                )}
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    E-POSTA ADRESİ
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    {...register('email', { required: true })}
                  />
                </div>
                {errors.email && (
                  <p>
                    <span className="error">{errors.email.message}</span>
                  </p>
                )}
                <div className="mb-3">
                  <label htmlFor="subject" className="form-label">
                    KONU
                  </label>
                  <select
                    {...register('subject', { required: true })}
                    className="form-select"
                    onChange={(e) => onChangeSelect(e)}
                  >
                    <option value="">Seçiniz</option>
                    {getTicketFields &&
                      getTicketFields.map((item, index) => (
                        <option
                          key={index}
                          label={item.name}
                          value={item.value}
                        >
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                {errors.subject && (
                  <p>
                    <span className="error">Bu alan zorunludur.</span>
                  </p>
                )}
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    MESAJ
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows={4}
                    {...register('message', { required: true })}
                  ></textarea>
                </div>
                {errors.message && (
                  <p>
                    <span className="error">Bu alan zorunludur.</span>
                  </p>
                )}
              </div>
              <div className="row mx-auto">
                <div className="col-sm-8 justify-content-center offset-sm-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="kvkk"
                      {...register('kvkk', { required: true })}
                    />
                    <label className="form-check-label" htmlFor="kvkk">
                      Kişisel verilerimin işlenmesine ilişkin{' '}
                      <a
                        className="form-check-link"
                        onClick={(event) => handleClick(event, 1)}
                        data-bs-toggle="modal"
                        data-bs-target="#permissionModal"
                        href="/"
                      >
                        Aydınlatma Metnini
                      </a>{' '}
                      okudum ve anladım.
                    </label>
                  </div>
                  {errors.kvkk && (
                    <p>
                      <span className="error">Bu alan zorunludur.</span>
                    </p>
                  )}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="share-permit"
                      {...register('permission', { required: true })}
                    />
                    <label className="form-check-label" htmlFor="share-permit">
                      <a
                        className="form-check-link"
                        href="/"
                        onClick={(event) => handleClick(event, 2)}
                        data-bs-toggle="modal"
                        data-bs-target="#permissionModal"
                      >
                        Aydınlatma Metnini
                      </a>{' '}
                      de dikkate alarak Açık Rıza`nız kapsamında kişisel
                      verilerinizin Mudi Finansal Teknoloji Hizmetleri A.Ş.
                      tarafından belirtilen yurtdışındaki iş ortakları,
                      tedarikçilere aktarılması amacıyla işlenmesine onay
                      veriyorsanız işaretleyiniz.
                    </label>
                  </div>
                  {errors.permission && (
                    <p>
                      <span className="error">Bu alan zorunludur.</span>
                    </p>
                  )}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="campaign"
                      ref={campaign}
                    />
                    <label className="form-check-label" htmlFor="campaign">
                      Mudi Finansal Teknoloji Hizmetleri A.Ş. tarafından benimle
                      e-posta yoluyla{' '}
                      <a
                        className="form-check-link"
                        href="/"
                        onClick={(event) => handleClick(event, 3)}
                        data-bs-toggle="modal"
                        data-bs-target="#permissionModal"
                      >
                        Ticari Elektronik İleti İzni
                      </a>{' '}
                      kapsamında kampanyalarımız hakkında iletişime geçilmesine
                      izin veriyorum.
                    </label>
                  </div>
                  <PermissionModal
                    openModal={openModal}
                    permissionKey={permissionKey}
                  />
                  <div className="row mb-120">
                    <div className="col-sm-8 mt-25 mlr-250"></div>
                    <button type="submit" className="form-button">
                      Gönder
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section className="pb-20" id="companyinformation">
        <div className="container mx-auto">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="support-header mb-0">Şirket Bilgileri</h2>
            </div>
          </div>

          <div className="row mx-auto mt-55">
            <div className="col-sm-5">
              <p className="contact-header">MERSİS Numarası</p>
              <p className="contact-detail">0631164211000001</p>
            </div>
            <div className="col-sm-7">
              <p className="contact-header">Adres</p>
              <p className="contact-detail">
                Levazım Mah. Koru Sok. Zorlu Center No:2 İç Kapı No:21
                Beşiktaş/İstanbul
              </p>
            </div>
          </div>
          <div className="row mx-auto mt-48">
            <div className="col-sm-5">
              <p className="contact-header">Ticari Sicil Numarası</p>
              <p className="contact-detail">351559-5</p>
            </div>
            <div className="col-sm-7">
              <p className="contact-header">E-posta Adresi</p>
              <p className="contact-detail">destek@getmundi.app</p>
            </div>
          </div>
          <div className="row mx-auto mt-48">
            <div className="col-sm-5">
              <p className="contact-header">KEP Adresi</p>
              <p className="contact-detail">nemateknoloji@hs01.kep.tr</p>
            </div>
            <div className="col-sm-7">
              <p className="contact-header">Telefon Numarası</p>
              <p className="contact-detail">0850 242 24 24</p>
            </div>
          </div>
          <div className="row">
            <div className="footer-line">&nbsp;</div>
          </div>
        </div>
      </section>
    </>
  )
}
