import React, { useState } from 'react'
import header from '../img/mudi-header.webp'
import header2 from '../img/Mudi-home2.webp'
import work from '../img/mudiwork.webp'
import workIcon1 from '../img/workicon1.webp'
import workIcon2 from '../img/workicon2.webp'
import workIcon3 from '../img/workicon3.webp'
import workIcon4 from '../img/workicon4.webp'
import headerQR from '../img/headerQR.webp'
import { AppDownloadModal } from './appdownloadmodal'
import { Features } from './feature'
import { HomeFaq } from './homefaq'
import { RatesModal } from './ratesmodal'
import { Slider } from './slider'
import { Meta } from '../meta'

export const Home = () => {
  const metaTitle = 'Mundi - Her tutar ile yatırım yap'
  const metaDescription =
    'Tek hesapla Türk Lirası ve Amerikan Doları birikimlerini değerlendir. Hemen dene!'
  Meta(metaTitle, metaDescription)

  const [openModal, setOpenModal] = useState(false)

  function handleClick(event) {
    event.preventDefault()
    setOpenModal(true)
  }

  return (
    <>
      <>
        <section>
          <div className="container">
            <div className="row common-margin-top-bottom">
              <div className="col-lg-6 header-image order-lg-last order-md-first order-sm-first order-3">
                <img className="img-fluid" src={header} alt="mundi"></img>{' '}
              </div>
              <div className="col-lg-6 order-lg-12 order-md-12 order-sm-12 order-1">
                <h1 className="header-title mb-0">
                  YENİ NESİL FİNTEK UYGULAMASI MUNDİ SAYESİNDE
                </h1>
                <div className="header-body">
                  Her tutar ile kolayca yatırım yap, birikimlerini değerlendirme
                  imkanına sahip ol.
                </div>
                <div className="row header-point-margin-top">
                  <div className="row mx-auto d-flex justify-content-between justify-content-md-start">
                    <span className="col-4 col-sm-3 col-md-2 col-lg-3 col-xl-2 header-point">
                      Güvenli
                    </span>
                    <span className="col-4 col-sm-3 col-md-2 col-lg-3 col-xl-2 header-point header-point-middle-margin">
                      Masrafsız
                    </span>
                    <span className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 header-point header-point-end-margin">
                      Kolay
                    </span>
                  </div>
                </div>
                <div className="header-message">
                  Mundi sayesinde, TL ve USD birikimlerini avantajlı getiri
                  oranlarıyla değerlendirme imkanına sahip olursun.
                </div>
                <div className="d-none d-sm-block mt-30">
                  <button
                    className="standart-button"
                    onClick={handleClick}
                    data-bs-toggle="modal"
                    data-bs-target="#ratesModal"
                  >
                    Güncel Nema Oranları
                  </button>{' '}
                </div>
                <div className="d-none d-sm-block mt-30">
                  <div>
                    <img className="headerQR-img" src={headerQR} alt="QR"></img>
                  </div>
                </div>
              </div>
              <div className="mt-3 d-block d-sm-none order-2">
                <button className="standart-button fullsize-button order-sm-1 order-md-3 w-100">
                  <a
                    className="reserve-button nounderline text-center d-sm-none"
                    href="https://2ykh.adj.st?adj_t=1dkrt8yx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Uygulamayı İndir
                  </a>
                </button>
              </div>
            </div>
          </div>
          <AppDownloadModal openModal={openModal} />
          <RatesModal />
          <div>
            <hr />
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="home-section2-body mb-0">
                  Mundi nasıl çalışır?
                </h2>
                <div className="home-section2-message">
                  <p>
                    <strong>
                      Mundi sayesinde, atıl duran TL ve USD birikimlerinin
                      finansal piyasalarda değerlendirilmesi ve nemalandırılması
                      için avantajlı oranlara ulaşabilirsin.
                    </strong>
                    <br />
                    <br className="d-block d-md-none" />
                    Bütün bu imkanlara Mundi sayesinde düzenleyici kamu
                    otoriteleri tarafından yetkilendirilmiş lisanslı kurumlarda
                    açtığın hesap üzerinden ulaşırsın.
                  </p>
                </div>
                <div className="d-none d-sm-none d-md-block">
                  <div className="col-sm-12 mt-60">
                    <img className="work-img" src={work} alt="mundi-work"></img>{' '}
                  </div>
                  <div className="row text-center mt-40 mb-60">
                    <div className="col-3">
                      <span className="work-text">
                        Mundi üzerinden SPK denetimindeki bir aracı kurumda
                        yatırım hesabı açarsın.
                      </span>
                    </div>
                    <div className="col-3">
                      <span className="work-text">
                        Yatırım hesabına değerlendirmek istediğin tutarı
                        aktarırsın. Birikimin ile tüm sermaye piyasası
                        ürünlerine yatırım yapabilirsin.
                      </span>
                    </div>
                    <div className="col-3">
                      <span className="work-text">
                        Aracı kurum, kalan birikiminin SPK düzenlemelerinde
                        belirlenen esaslar çerçevesinde, avantajlı koşullarla
                        nemalanmasını sağlar.
                      </span>
                    </div>
                    <div className="col-3">
                      <span className="work-text">
                        Birikimini hem finansal piyasalarda değerlendirme hem de
                        avantajlı oranlar ile güvenle nemalandırma imkanına
                        sahip olursun.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-md-none">
                  <div className="row mt-3 mb-3">
                    <div className="d-flex">
                      <img
                        className="work-icon"
                        src={workIcon1}
                        alt="mundi-work-icon"
                      />
                      <div className="work-text ms-3 text-start my-auto">
                        Mundi üzerinden SPK denetimindeki bir aracı kurumda
                        yatırım hesabı açarsın.
                      </div>
                    </div>
                    <div>
                      <span className="work-line"></span>
                    </div>
                    <div className="d-flex">
                      <img
                        className="work-icon"
                        src={workIcon2}
                        alt="mundi-work-icon"
                      />
                      <div className="work-text ms-3 text-start my-auto">
                        Yatırım hesabına değerlendirmek istediğin tutarı
                        aktarırsın. Birikimin ile tüm sermaye piyasası
                        ürünlerine yatırım yapabilirsin.
                      </div>
                    </div>
                    <div>
                      <span className="work-line"></span>
                    </div>
                    <div className="d-flex">
                      <img
                        className="work-icon"
                        src={workIcon3}
                        alt="mundi-work-icon"
                      />
                      <div className="work-text ms-3 text-start my-auto">
                        Aracı kurum, kalan birikiminin SPK düzenlemelerinde
                        belirlenen esaslar çerçevesinde, avantajlı koşullarla
                        nemalanmasını sağlar.
                      </div>
                    </div>
                    <div>
                      <span className="work-line"></span>
                    </div>
                    <div className="d-flex">
                      <img
                        className="work-icon"
                        src={workIcon4}
                        alt="mundi-work-icon"
                      />
                      <div className="work-text ms-3 text-start my-auto">
                        Birikimini hem finansal piyasalarda değerlendirme hem de
                        avantajlı oranlar ile güvenle nemalandırma imkanına
                        sahip olursun.
                      </div>
                    </div>
                    <div className="mt-30 d-block d-sm-none">
                      <button className="standart-button fullsize-button order-sm-1 order-md-3 w-100">
                        <a
                          className="reserve-button nounderline text-center d-sm-none"
                          href="https://2ykh.adj.st?adj_t=1dkrt8yx"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Hemen Başla
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="GuncelFaizOranlari" className="bg1">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="home-section3-body">
                  <p>
                    <span className="home-section3-body">Güncel</span>{' '}
                    <span className="home-section3-text">getirini hesapla</span>
                  </p>
                </div>
                <div className="home-section3-message">
                  Güncel nema oranlarını inceleyerek getirini hesaplayabilirsin.
                </div>
                <div className="mt-30 ">
                  <button
                    className="standart-button d-none d-sm-block"
                    onClick={handleClick}
                    data-bs-toggle="modal"
                    data-bs-target="#ratesModal"
                  >
                    Güncel Nema Oranları
                  </button>{' '}
                  <button
                    className="standart-button fullsize-button order-sm-1 order-md-3 d-sm-none w-100"
                    onClick={handleClick}
                    data-bs-toggle="modal"
                    data-bs-target="#ratesModal"
                  >
                    Güncel Nema Oranları
                  </button>
                </div>
              </div>
              <RatesModal />
              <div className="col-sm-6">
                <div className="home-section3-point-mt">
                  <p className="home-section3-point">
                    Tutardan bağımsız <strong>herkese</strong> aynı oranda{' '}
                    <strong>yüksek getiri imkanı sağlar</strong>.
                  </p>
                  <p className="home-section3-point">
                    <strong>Şeffaf gelir modeli</strong> prensibiyle aracı kurum
                    partnerinin aldığı komisyon bilgisini kullanıcısıyla
                    paylaşır.
                  </p>
                  <p className="home-section3-point">
                    Hiçbir zaman kullanıcısından{' '}
                    <strong>üyelik ücreti almaz</strong>.
                  </p>
                </div>
              </div>
              <div className="mb-120"></div>
            </div>
          </div>
        </section>
        <section className="bg2">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12 mb-60 order-md-first">
                <div className="home-section4-title">
                  Birikimin güvenle değerlenir
                </div>
                <div className="col-sm-12 mt-10">
                  <div className="row">
                    <div className="row m-auto">
                      <div className="col-sm-5 col-md-5 home-section4-border">
                        <div className="spk-ytm-textbox">
                          <p className="home-section4-body">
                            Sermaye Piyasası Kurulu (SPK) lisanslı aracı kurum
                            partneri
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-5 col-md-5 home-section4-border">
                        <div className="spk-ytm-textbox">
                          <p className="home-section4-body">
                            Sermaye piyasasında değerlendirdiğin yatırımların
                            için 1.143.379 TL devlet güvencesi (YTM)
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-30">
                      <div className="mt-10">
                        <div className="col-2 mt-10">
                          <span className="circle">
                            <strong>1</strong>
                          </span>
                        </div>
                        <div className="col-10 home-section4-text">
                          Mundi kullanıcılarının yatırım hesapları Sermaye
                          Piyasası Kurulu (SPK) ve Mali Suçları Araştırma Kurulu
                          (MASAK) denetimine tabi bir aracı kurumda açılır.
                        </div>
                      </div>
                      <div className="mt-10">
                        <div className="col-2 mt-10">
                          <span className="circle">
                            <strong>2</strong>
                          </span>
                        </div>
                        <div className="col-10 home-section4-text">
                          Yatırımcı Tazmin Merkezi’nin güvencesiyle 2024 yılı
                          için yatırım hesabında portföyün 1.143.379 TL’ye kadar
                          tazmin kapsamındadır. Bu tutar her yıl ilan edilen
                          yeniden değerleme katsayısı oranında artırılır.
                        </div>
                      </div>

                      <div className="mt-10">
                        <div className="col-2 mt-10">
                          <span className="circle">
                            <strong>3</strong>
                          </span>
                        </div>
                        <div className="col-10 home-section4-text">
                          <span>
                            Mundi, senden aldığı bilgileri resmi kurumlar ve iş
                            ortakları haricinde üçüncü şahıs veya şirketlerle{' '}
                            <u>hiçbir şart ve koşulda</u> paylaşmaz.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 mt-120 m-auto order-md-12 order-sm-12 text-center">
                <img className="img-fluid" src={header2} alt="mundi"></img>{' '}
              </div>
            </div>
          </div>
        </section>
        <section id="features">
          <Features />
        </section>
        <section className="bg1">
          <Slider />
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="faq-title">Sıkça Sorulan Sorular</div>
                <HomeFaq />
                <div className="row">
                  <div className="text-center">
                    <p className="mt-55 mb-120">
                      <a
                        className="invitation-button-text nounderline"
                        href="/destek#SSS"
                      >
                        Daha Fazlası
                      </a>{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  )
}
