import React from 'react'
import product1 from '../img/productheader1.webp'
import product2 from '../img/productheader2.webp'
import circle from '../img/check-circle2.webp'
import circlecancel from '../img/circlecancel.webp'
import productmobile from '../img/productheadermobile.webp'
import { FeatureFaq } from './featurefaq'
import { Features } from './feature'
import { Slider } from './slider'
import { DownloadButton } from './downloadbutton'
import { Meta } from '../meta'

export const Product = () => {
  const metaTitle = 'Mundi Özellikler - Tek yerden birikimlerini değerlendir'
  const metaDescription =
    'TL ve USD birikimlerini değerlendir. Ücretsiz para transferi yap. Mundi hakkında daha fazla bilgi için tıkla.'
  Meta(metaTitle, metaDescription)

  return (
    <>
      <>
        <section>
          <div className="container">
            <div className="row m-auto">
              <div className="col-sm-3 d-none d-sm-block">
                <div className="product-banner-left">
                  <img
                    className="product-banner-img"
                    src={product1}
                    alt="product"
                  ></img>{' '}
                </div>
              </div>
              <div className="col-sm-6 m-auto">
                <h1 className="product-header-title mb-10 pt-3">
                  Birikimin yeni nesli <br /> Herkesin{' '}
                  <span className="product-header-title-color">Mundi</span>
                  {"'"}si
                </h1>
                <div className="product-header-message mb-60 m-sm-5">
                  Mundi, TL ve USD birikimlerini sermaye
                  <br className="d-block d-sm-none" /> piyasası ürünlerinde
                  değerlendirmeni ve
                  <br className="d-block d-sm-none" /> hesabında kalan bakiyeni
                  avantajlı
                  <br className="d-block d-sm-none" /> oranlarla nemalandırmanı
                  sağlar.
                </div>
                <div className="mb-60">
                  <DownloadButton />
                </div>
              </div>
              <div className="col-sm-3 d-none d-sm-block">
                <div className="product-banner-right">
                  <img
                    className="product-banner-img mt-5"
                    src={product2}
                    alt="product"
                  ></img>{' '}
                </div>
              </div>
              <div className="col-sm-12 d-block d-sm-none">
                <img
                  className="product-header-banner"
                  src={productmobile}
                  alt="product"
                ></img>{' '}
              </div>
            </div>
          </div>
        </section>
        <section id="NedenMundi" className="bg1">
          <Features />
        </section>
        <section id="ÜrünÖzellikleri">
          <Slider />
        </section>
        <section id="SeffafGelirModeli" className="bg2">
          <div className="container">
            <div className="row">
              <div className="row commission-margin-top-bottom col-sm-12 mx-auto pt-5">
                <h2 className="product-income-title mb-0 text-center">
                  Şeffaf Gelir Modeli
                </h2>
                <div className="mt-30 text-center">
                  <div className="col-sm-12 product-income-text mx-auto">
                    Aracı kurum partnerimiz, kullanıcılarımıza sağladığı
                    avantajlı nema oranları
                    <br className="d-none d-md-block" /> üzerinden komisyon
                    alır. Komisyon oranı TL birikimlerin için yıllık %1,50,
                    <br className="d-none d-md-block" /> USD birikimlerin için
                    ise yıllık %0,75 olarak uygulanır.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="KarsilastirmaTablosu">
          <div className="container">
            <div className="row">
              <div className="justify-content-center mx-auto product-table-mt">
                <h2 className="product-table-title text-center mb-0">
                  Karşılaştırma Tablosu
                </h2>
              </div>
            </div>
            <div className="row mt-25 mb-120 justify-content-center mx-auto">
              <div className="col-sm-12 mx-auto">
                <ul
                  className="nav nav-pills justify-content-center product-table-nav mx-auto"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item table-nav-item" role="presentation">
                    <button
                      className="nav-link table-nav w-100 active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      <h3 className="product-table-nav-text text-center mb-0">
                        Özellikler
                      </h3>
                    </button>
                  </li>
                  <li className="nav-item table-nav-item" role="presentation">
                    <button
                      className="nav-link table-nav w-100"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      <h3 className="product-table-nav-text mb-0">Ücretler</h3>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="col-md-12 col-sm-10 mt-50 table-responsive-xs d-none d-sm-none d-md-block">
                    <table className="table table-properties product-table">
                      <thead>
                        <tr className="product-table-bg">
                          <th className="product-table-header">
                            <div className="row">
                              <div className="product-table-header-text">
                                İşlem
                              </div>
                            </div>
                          </th>
                          <th className="product-table-header-mudi position-relative">
                            <span className="product-table-header-text product-table-visible-text">
                              Mundi{"'"}de
                            </span>
                          </th>
                          <th className="product-table-header">
                            <div className="row">
                              <div className="product-table-header-text">
                                Piyasada
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="product-table-content-text">
                              <strong>Getiri</strong>
                            </div>
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circle}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                Her tutar için alt limit olmadan avantajlı nema
                                oranları alırsın.
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circlecancel}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                Ancak yüksek tutarlar için avantajlı nema
                                oranları alırsın.
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="product-table-content-text">
                              <strong>Hoş geldin kampanyası</strong>
                            </div>
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circle}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                Her zaman rekabetçi getiri oranları elde
                                edersin.
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circlecancel}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                Hoş geldin kampanyasının süresi dolduktan sonra
                                daha düşük oranlar alırsın.
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="product-table-content-text">
                              <strong>Vadeli dolar hesabı</strong>
                            </div>
                          </td>
                          <td className="last-tablo-row-border">
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circle}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                Alt limit yok.
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circlecancel}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                500 - 1.000 USD arasında değişen bir alt limitle
                                karşılaşırsın.
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-12 d-md-none">
                    <div
                      className="accordion mt-50 mb-60"
                      id="compareaccordionExample"
                    >
                      <div className="faq-collapse-body">
                        <span id="compareheadingOne">
                          <button
                            className="accordion-button bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#comparecollapseOne"
                            aria-expanded="true"
                            aria-controls="comparecollapseOne"
                          >
                            <strong className="faq-message-title">
                              Getiri
                            </strong>
                          </button>
                        </span>
                        <div
                          id="comparecollapseOne"
                          className="collapse show bg3"
                          aria-labelledby="compareheadingOne"
                          data-bs-parent="#compareaccordionExample"
                        >
                          <div className="faq-message-text">
                            <div className="row">
                              {' '}
                              <div className="col-sm-12 collapse-subtitle">
                                Mundi{"'"}de
                              </div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circle}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    {' '}
                                    Her tutar için alt limit olmadan avantajlı
                                    nema oranları alırsın.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              {' '}
                              <div className="col-sm-12">Piyasada</div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circlecancel}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    Ancak yüksek tutarlar için avantajlı nema
                                    oranları alırsın.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="compareheadingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#comparecollapseTwo"
                            aria-expanded="true"
                            aria-controls="comparecollapseTwo"
                          >
                            <strong className="faq-message-title">
                              Hoş geldin kampanyası
                            </strong>
                          </button>
                        </span>
                        <div
                          id="comparecollapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="compareheadingTwo"
                          data-bs-parent="#compareaccordionExample"
                        >
                          <div className="faq-message-text">
                            <div className="row">
                              {' '}
                              <div className="col-sm-12 collapse-subtitle">
                                Mundi{"'"}de
                              </div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circle}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    Her zaman rekabetçi getiri oranları elde
                                    edersin.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              {' '}
                              <div className="col-sm-12">Piyasada</div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circlecancel}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    Hoş geldin kampanyasının süresi dolduktan
                                    sonra daha düşük oranlar alırsın.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="compareheadingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#comparecollapseFour"
                            aria-expanded="true"
                            aria-controls="comparecollapseFour"
                          >
                            <strong className="faq-message-title">
                              Vadeli dolar hesabı
                            </strong>
                          </button>
                        </span>
                        <div
                          id="comparecollapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="compareheadingFour"
                          data-bs-parent="#compareaccordionExample"
                        >
                          <div className="faq-message-text">
                            <div className="row">
                              {' '}
                              <div className="col-sm-12 collapse-subtitle">
                                Mundi{"'"}de
                              </div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circle}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    Alt limit yok.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              {' '}
                              <div className="col-sm-12">Piyasada</div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circlecancel}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    {' '}
                                    500 - 1.000 USD arasında değişen bir alt
                                    limitle karşılaşırsın.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="col-md-12 col-sm-10 mt-50 table-responsive-xs d-none d-sm-none d-md-block">
                    <table className="table table-properties product-table">
                      <thead>
                        <tr className="product-table-bg">
                          <th className="product-table-header">
                            <div className="row">
                              <div className="product-table-header-text">
                                İşlem
                              </div>
                            </div>
                          </th>
                          <th className="product-table-header-mudi position-relative">
                            <span className="product-table-header-text product-table-visible-text">
                              Mundi{"'"}de
                            </span>
                          </th>
                          <th className="product-table-header">
                            <div className="row">
                              <div className="product-table-header-text">
                                Piyasada
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="product-table-content-text">
                              <strong>Havale</strong>
                            </div>
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circle}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                Ücret Alınmaz.
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circlecancel}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                Çoğu zaman ücretlidir.
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="product-table-content-text">
                              <strong>EFT</strong>
                            </div>
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circle}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                Ücret Alınmaz.
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circlecancel}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                Çoğu zaman ücretlidir.
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="product-table-content-text">
                              <strong>SWIFT</strong>
                            </div>
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circle}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                Ücret Alınmaz.
                              </div>
                            </div>
                          </td>
                          <td>
                            {' '}
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circlecancel}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                Ücretlidir.
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="product-table-content-text">
                              <strong>Hesap işletim</strong>
                            </div>
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circle}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                Ücret Alınmaz.
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circlecancel}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                Çoğu zaman ücretlidir.
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="product-table-content-text">
                              <strong>
                                Kart (yıllık aidat, işlem ücreti, vb.)
                              </strong>
                            </div>
                          </td>
                          <td className="last-tablo-row-border">
                            <div className="row">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circle}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto pe-auto">
                                {' '}
                                Ücret Alınmaz.
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="row justify-content-center">
                              <div className="col-1 m-auto">
                                <img
                                  className="product-img-size"
                                  src={circle}
                                  alt="vector"
                                ></img>
                              </div>
                              <div className="product-table-content-text col-10 m-auto">
                                {' '}
                                Ücret Alınmaz.
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-12 d-md-none">
                    <div
                      className="accordion mt-50 mb-60"
                      id="comparemoneyaccordionExample"
                    >
                      <div className="faq-collapse-body">
                        <span id="comparemoneyheadingOne">
                          <button
                            className="accordion-button bg1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#comparemoneycollapseOne"
                            aria-expanded="true"
                            aria-controls="comparemoneycollapseOne"
                          >
                            <strong className="faq-message-title">
                              Havale{' '}
                            </strong>
                          </button>
                        </span>
                        <div
                          id="comparemoneycollapseOne"
                          className="collapse show bg3"
                          aria-labelledby="comparemoneyheadingOne"
                          data-bs-parent="#comparemoneyaccordionExample"
                        >
                          <div className="faq-message-text">
                            <div className="row">
                              {' '}
                              <div className="col-sm-12 collapse-subtitle">
                                Mundi{"'"}de
                              </div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circle}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    {' '}
                                    Ücret Alınmaz.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              {' '}
                              <div className="col-sm-12">Piyasada</div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circlecancel}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    {' '}
                                    Çoğu zaman ücretlidir.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="comparemoneyheadingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#comparemoneycollapseTwo"
                            aria-expanded="true"
                            aria-controls="comparemoneycollapseTwo"
                          >
                            <strong className="faq-message-title">EFT</strong>
                          </button>
                        </span>
                        <div
                          id="comparemoneycollapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="comparemoneyheadingTwo"
                          data-bs-parent="#comparemoneyaccordionExample"
                        >
                          <div className="faq-message-text">
                            <div className="row">
                              {' '}
                              <div className="col-sm-12 collapse-subtitle">
                                Mundi{"'"}de
                              </div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circle}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    {' '}
                                    Ücret Alınmaz.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              {' '}
                              <div className="col-sm-12">Piyasada</div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circlecancel}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    Çoğu zaman ücretlidir.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="comparemoneyheadingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#comparemoneycollapseThree"
                            aria-expanded="true"
                            aria-controls="comparemoneycollapseThree"
                          >
                            <strong className="faq-message-title">SWIFT</strong>
                          </button>
                        </span>
                        <div
                          id="comparemoneycollapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="comparemoneyheadingThree"
                          data-bs-parent="#comparemoneyaccordionExample"
                        >
                          <div className="faq-message-text">
                            <div className="row">
                              {' '}
                              <div className="col-sm-12 collapse-subtitle">
                                Mundi{"'"}de
                              </div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circle}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    Ücret Alınmaz.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              {' '}
                              <div className="col-sm-12">Piyasada</div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circlecancel}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    Ücretlidir.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="comparemoneyheadingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#comparemoneycollapseFour"
                            aria-expanded="true"
                            aria-controls="comparemoneycollapseFour"
                          >
                            <strong className="faq-message-title">
                              Hesap işletim
                            </strong>
                          </button>
                        </span>
                        <div
                          id="comparemoneycollapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="comparemoneyheadingFour"
                          data-bs-parent="#comparemoneyaccordionExample"
                        >
                          <div className="faq-message-text">
                            <div className="row">
                              {' '}
                              <div className="col-sm-12 collapse-subtitle">
                                Mundi{"'"}de
                              </div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circle}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    Ücret Alınmaz.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              {' '}
                              <div className="col-sm-12">Piyasada</div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circlecancel}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    Çoğu zaman ücretlidir.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="faq-collapse-body">
                        <span id="comparemoneyheadingSix">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#comparemoneycollapseSix"
                            aria-expanded="true"
                            aria-controls="comparemoneycollapseSix"
                          >
                            <strong className="faq-message-title">
                              Kart (yıllık aidat, işlem ücreti, vb.)
                            </strong>
                          </button>
                        </span>
                        <div
                          id="comparemoneycollapseSix"
                          className="accordion-collapse collapse"
                          aria-labelledby="comparemoneyheadingSix"
                          data-bs-parent="#comparemoneyaccordionExample"
                        >
                          <div className="faq-message-text">
                            <div className="row">
                              {' '}
                              <div className="col-sm-12 collapse-subtitle">
                                Mundi{"'"}de
                              </div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circle}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    Ücret Alınmaz.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              {' '}
                              <div className="col-sm-12">Piyasada</div>
                              <div className="col-sm-12 mt-2">
                                <div className="row">
                                  <div className="col-1">
                                    <img
                                      className="product-img-size"
                                      src={circle}
                                      alt="vector"
                                    ></img>
                                  </div>
                                  <div className="product-table-content-text col-10">
                                    Ücret Alınmaz.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr className="d-none d-sm-block" />
        <section>
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="faq-title mb-0">Sıkça Sorulan Sorular</h2>
                <FeatureFaq />
                <div className="row">
                  <div className="text-center">
                    <p className="mt-55 mb-120">
                      <a
                        className="invitation-button-text nounderline"
                        href="/destek#Faq"
                      >
                        Daha Fazlası
                      </a>{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  )
}
