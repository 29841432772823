import Joi from 'joi'
import { TicketModel, CorporateTicketModel } from './model'

const validator = {
  ticketSchema: Joi.object<TicketModel>({
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    email: Joi.string()
      .lowercase()
      .email({ minDomainSegments: 2, tlds: { allow: false } })
      .message('Lütfen geçerli bir E-posta adresi giriniz.')
      .required()
      .messages({ 'string.empty': 'Bu alan zorunludur.' }),
    subject: Joi.string().required(),
    message: Joi.string().required(),
    kvkk: Joi.boolean().required().invalid(false),
    permission: Joi.boolean().required().invalid(false),
  }),

  coporateTicketSchema: Joi.object<CorporateTicketModel>({
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    phoneNumber: Joi.number().required(),
    email: Joi.string()
      .lowercase()
      .email({ minDomainSegments: 2, tlds: { allow: false } })
      .message('Lütfen geçerli bir E-posta adresi giriniz.')
      .required()
      .messages({ 'string.empty': 'Bu alan zorunludur.' }),
    corporateName: Joi.string().required(),
    vkn: Joi.number().required(),
    userAgreement: Joi.boolean().required().invalid(false),
    kvkk: Joi.boolean().required().invalid(false),
    permission: Joi.boolean().required().invalid(false),
  }),
}

export default validator
